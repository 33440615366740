import React from "react";
import CardPricingDetails from "components/Cards/CardPricingDetails";

const Pricing4 = (props) => {
  const { cards, name, token, plan } = props;

  const onSubscribe = (event, redirectUrl, planCode) => {
    if (plan === ""){
      event.preventDefault();
      var postData = new URLSearchParams();
      postData.append("username", name);
      postData.append("planCode", planCode);
      // POST request with a JSON body using fetch
      const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      body: postData.toString(),
    };
    requestOptions.headers["Authorization"] = `Bearer ${token}`;

    fetch(
      `${process.env.REACT_APP_PGA_USER_API_URL}/User/CreateZohoSubscriptionPage`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        //debugger;
        console.log(data)
        if (data.isSucces === true) {
          if(planCode.includes("free") || planCode.startsWith("Free"))
          {
            window.location.href = "/gettingstarted";         
          }else{
            window.location.href = data.result.url;
          }
        }
      });
    }
    else {
      window.location.href = redirectUrl
    }
    
  };


  console.log(cards)


  return (
    <>
      <div>
        <div className="">
          <div className="flex flex-wrap -mx-4">
            <div className="mx-auto py-4 mb-4"></div>
          </div>
          <div className="flex flex-wrap">
            {cards.map(function (prop, key) {
              return (
                <div key={key} className="px-4 py-4 relative w-full md:w-4/12 lg:w-3/12">
                  <CardPricingDetails onSubscribe={onSubscribe} {...prop} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing4;
