import React from 'react';
import IndexNavbar from "../../components/Navbars/Navbar.js";
import Footer from "../../components/Footers/Footer.js";

function TermsOfUse() {
    return (
        <>
            <IndexNavbar fixed />
            <section
                id="early-access" className="header relative pt-20 pb-16 items-center flex " >
                <div className="container mx-auto items-center justify-center flex flex-wrap" id="register" >
                    <div className='container mx-auto'>
                        <div class="relative px-4 sm:px-6 lg:px-8">
                            <div class="text-lg max-w-prose mx-auto mb-6">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center ">Terms of Use</h2>
                            </div>
                        </div>
                        <div>
                            <div class="prose text-gray-500 mx-auto">
                                <p>Welcome to PowerPointGeneratorAPI.com (the "Website"). These Terms of Use ("Terms") govern your use of the Website and the services provided by PowerPointGeneratorAPI.com ("Service" or "Services"). Please read these Terms carefully before using the Website or accessing the Services.</p>
                            </div>
                            <div style={{ marginLeft: "30px" }}>
                                <ul style={{ listStyle: "disc" }}>
                                    <li class="py-2">
                                        <p>Acceptance of Terms By accessing or using the Website or Services, you agree to be bound by these Terms. If you do not agree with these Terms, please refrain from using the Website and Services.</p>
                                    </li>
                                    <li class="py-2">
                                        <p>Use of the Services</p>
                                    </li>
                                </ul>
                                <p>2.1 Eligibility You must be at least 18 years old to use the Services. By using the Services, you represent and warrant that you are at least 18 years old.</p>
                                <p>2.2 License Subject to these Terms, PowerPointGeneratorAPI.com grants you a limited, non-exclusive, non-transferable, and revocable license to use the Services for personal or commercial purposes. This license is granted solely for the purpose of generating PowerPoint presentations using the API provided by PowerPointGeneratorAPI.com.</p>
                                <p>2.3 Restrictions You agree not to:</p>
                                <div style={{ marginLeft: "30px" }}>
                                    <ul style={{ listStyle: "disc" }}>
                                        <li class="py-2">Use the Services for any unlawful, fraudulent, or malicious activities</li>
                                        <li class="py-2">Violate any applicable laws, regulations, or third-party rights.</li>
                                        <li class="py-2">Reverse engineer, decompile, or attempt to extract the source code of the Services.</li>
                                        <li class="py-2">Modify, adapt, or create derivative works of the Services.</li>
                                        <li class="py-2">Sell, sublicense, rent, lease, or distribute the Services to any third party.</li>
                                        <li class="py-2">Use the Services in a manner that may impair the performance, availability, or integrity of the Services or the Website.</li>
                                    </ul>
                                </div>

                                <p>2.4 Usage Limitations PowerPointGeneratorAPI.com reserves the right to impose usage limitations on the Services, including but not limited to the number of API calls, requests, or generated presentations. PowerPointGeneratorAPI.com may modify or revoke these limitations at any time without prior notice.</p>
                                <div style={{ marginLeft: "30px" }}>
                                    <ul style={{ listStyle: "disc" }}>
                                        <li class="py-2">
                                            <p>User Account To access certain features of the Services, you may need to create a user account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to provide accurate and complete information during the registration process. PowerPointGeneratorAPI.com reserves the right to suspend or terminate your account if any information provided is inaccurate, misleading, or violates these Terms.</p>
                                        </li>
                                        <li class="py-2">
                                            <p>Intellectual Property Rights</p>
                                        </li>
                                    </ul>
                                </div>

                                <p>4.1 Ownership The Website, Services, and all related content, including but not limited to logos, trademarks, texts, images, and software, are the property of PowerPointGeneratorAPI.com or its licensors. All rights not expressly granted herein are reserved.</p>
                                <p>4.2 User Content By using the Services, you grant PowerPointGeneratorAPI.com a worldwide, royalty-free, non-exclusive license to use, reproduce, modify, adapt, publish, distribute, and display the content you upload or generate using the Services for the purpose of providing and improving the Services.</p>

                                <p>4.3 Feedback You may provide feedback, suggestions, or ideas to PowerPointGeneratorAPI.com regarding the Services ("Feedback"). You acknowledge that Feedback is voluntary and PowerPointGeneratorAPI.com may use, modify, or incorporate Feedback into its products or services without any obligation to you.</p>
                                <div style={{ marginLeft: "30px" }}>
                                    <ul style={{ listStyle: "disc" }}>
                                        <li class="py-2">
                                            <p>Privacy PowerPointGeneratorAPI.com respects your privacy and handles your personal information in accordance with its Privacy Policy. By using the Services, you consent to the collection, use, and disclosure of your personal information as described in the Privacy Policy.</p>

                                        </li>
                                        <li class="py-2">
                                            <p>Disclaimer of WarrantyThe Services are provided on an "as-is" and "as available" basis without warranties of any kind, either express or implied. PowerPointGeneratorAPI.com disclaims all warranties, including but not limited to the accuracy, reliability, suitability, or availability of the Services. You acknowledge that your use of the Services is at your own risk.</p>
                                        </li>
                                        <li class="py-2">
                                            <p>Limitation of Liability To the maximum extent permitted by applicable law, PowerPointGeneratorAPI.com and its affiliates, officers, directors, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to lost profits, data loss, or business interruption arising out of or in connection with the use of the Services or these Terms. This limitation applies regardless of the cause of action, whether in contract, tort, or otherwise.</p>

                                        </li>
                                        <li class="py-2">
                                            <p>Indemnification You agree to indemnify and hold PowerPointGeneratorAPI.com and its affiliates, officers, directors, employees, and agents harmless from any claims, damages, liabilities, or expenses arising out of or in connection with your use of the Services, violation of these Terms, or infringement of any third-party rights.</p>

                                        </li>
                                        <li class="py-2">
                                            <p>Modifications to the Terms PowerPointGeneratorAPI.com reserves the right to modify or replace these Terms at any time without prior notice. It is your responsibility to review the updated Terms periodically. Your continued use of the Services after any modifications constitutes acceptance of the revised Terms.</p>

                                        </li>
                                        <li class="py-2">
                                            <p>Termination PowerPointGeneratorAPI.com may, at its sole discretion, suspend or terminate your access to the Services at any time and for any reason, without liability to you. Upon termination, all licenses and rights granted to you under these Terms will cease.</p>

                                        </li>
                                        <li class="py-2">
                                            <p>Governing Law and Jurisdiction These Terms shall be governed by and construed in accordance with the laws of United Kingdom, without regard to its conflict of laws principles. Any dispute arising out of or in connection with these Terms shall be submitted to the exclusive jurisdiction of the courts of United Kingdom.</p>

                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <br></br>
                            <p>If you have any questions or concerns about these Terms, please contact us at <a href="mailto:info@powerpointgeneratorapi.com">info@powerpointgeneratorapi.com</a>.</p>
                            <br></br>
                            <p>Last updated: 01-06-2023</p>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    );
}

export default TermsOfUse;
