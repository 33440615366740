import React from "react";

const PowerfulEasy = (props) => {
  return (
    <>
      <div className="container mx-auto px-4 pb-4 pt-16" id="documentation">
        <div className="justify-center text-center flex flex-wrap mt-24">
          <div className="w-full md:w-6/12 px-12 md:px-4">
            <h2 className="font-semibold text-4xl">
              Powerful and easy-to-use API
            </h2>
            <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
              Designed for developers. Easily get started and integrate.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full md:w-6/12 sm:w-8/12  mr-auto ml-auto ">
        <div className="w-full flex flex-row justify-center items-center">
          <div className="mt-6">
            <a
              href="https://docs.powerpointgeneratorapi.com/"
              target="_blank"
              rel="noreferrer"
              className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
            >
              Read our docs
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default PowerfulEasy;
