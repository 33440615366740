import React from "react";

const WhatsIncluded = (props) => {
  return (
    <div className="container mx-auto overflow-hidden pb-20" id="what-included">
      <div className="flex flex-wrap items-center">
        <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
          <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
            <i className="fas fa-drafting-compass text-xl"></i>
          </div>
          <h3 className="text-3xl mb-2 font-semibold leading-normal">
            What's included
          </h3>
          <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
            Powerful and flexible API.
          </p>
          <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
            Automate all facets of PowerPoint generation
          </p>
        </div>
        <div className="w-full md:w-6/12 px-4 mr-auto ml-auto mt-32">
          <div className="justify-center flex flex-wrap relative">
            <div className="my-4 w-full lg:w-6/12 px-4">
              <div className="bg-red-600 shadow-lg rounded-lg text-center p-8">
                <i className="far fa-chart-bar text-4xl text-white"></i>
                <p className="text-lg text-white mt-4 font-semibold">Charts</p>
                <p className="text-sm text-white mt-4">
                  Fill chart shapes with your data. Populate any PowerPoint
                  chart type and configure any chart properties.
                </p>
              </div>

              <div className="bg-lightBlue-500 shadow-lg rounded-lg text-center p-8 mt-8">
                <i className="fas fa-table text-4xl text-white"></i>
                <p className="text-lg text-white mt-4 font-semibold">Tables</p>
                <p className="text-sm text-white mt-4">
                  Fill table shapes on your pre-styled template PPTX. Work with
                  merged cells and configure all cell property types.
                </p>
              </div>

              <div className="bg-blueGray-700 shadow-lg rounded-lg text-center p-8 mt-8">
                <i className="far fa-images text-4xl text-white"></i>
                <p className="text-lg text-white mt-4 font-semibold">Images</p>
                <p className="text-sm text-white mt-4">
                  Insert images into your presentation programmatically. Using
                  placeholder images or even insert images into table cells.
                </p>
              </div>
            </div>
            <div className="my-4 w-full lg:w-6/12 px-4 lg:mt-16">
              <div className="bg-yellow-500 shadow-lg rounded-lg text-center p-8">
                <i className="fas fa-font text-4xl text-white"></i>
                <p className="text-lg text-white mt-4 font-semibold">
                  Textboxes
                </p>
                <p className="text-sm text-white mt-4">
                  Insert your text into existing text boxes.
                </p>
              </div>

              <div className="bg-red-700 shadow-lg rounded-lg text-center p-8 mt-8">
                <i className="fas fa-tags text-4xl text-white"></i>
                <p className="text-lg text-white mt-4 font-semibold">Tagging</p>
                <p className="text-sm text-white mt-4">
                  Use powerful tagging to automate updating repeated information
                  like; dates, customer names, project IDs across your whole
                  presentation instantly.
                </p>
              </div>

              <div className="bg-emerald-500 shadow-lg rounded-lg text-center p-8 mt-8">
                <i className="fas fa-copyright text-4xl text-white"></i>
                <p className="text-lg text-white mt-4 font-semibold">Styling</p>
                <p className="text-sm text-white mt-4">
                  Use your pre-styled PowerPoint slides as templates for your
                  programmatically created presentations. PowerPoint Generator
                  API will maintain your styling.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsIncluded;
