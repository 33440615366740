import styles from "./Card.module.css";

const CardFurtherExamples = (props) => {
  
  return (
    <div
      class="shadow-lg"
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        paddingInline: "10px",
        gridArea: "furtherExamples",
        height: "fit-content",
        paddingBottom: "25px",
        borderRadius: "0.25rem",
        overflow: "hidden",
        backgroundColor: "white",
      }}
    >
      <h1 className={styles.cardTitle}>FURTHER EXAMPLES</h1>
      <h1
        style={{
          fontWeight: "bold",
          fontSize: 18,
          paddingTop: "5px",
        }}
      >
        For more examples, take a look at the documentation
      </h1>
      <a
        href="https://docs.powerpointgeneratorapi.com/"
        rel="noreferrer noopener"
        target="_blank"
      >
        https://docs.powerpointgeneratorapi.com/
      </a>
    </div>
  );
};

export default CardFurtherExamples;
