import React from "react";

const AboutPGA = (props) => {
  return (
    <section className="bg-white overflow-hidden">
      <div className="container mx-auto pb-32  pt-12">
        <div className="container mx-auto px-4 pb-6" id="about">
          <div className="justify-center text-center flex flex-wrap mt-24">
            <div className="w-full md:w-6/12 px-12 md:px-4">
              <h2 className="font-semibold text-4xl text-blueGray-600">
                About PowerPoint Generator API
              </h2>            
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-center">
          <div className="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto">
            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
              <i className="fas fa-thumbs-up text-xl"></i>
            </div>
            <h3 className="text-3xl mb-2 font-semibold leading-normal text-blueGray-500">
              Trusted By
            </h3>
            <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-500">
              Leaders in automated data visualization and PowerPoint automation
            </p>
          </div>
          <div className="w-full md:w-6/12 px-12 md:px-4 ml-auto mr-auto ">
            <div className="flex flex-wrap">
              <div className="bg-white rounded-lg p-2 m-4 md:w-3/12 justify-center items-center flex">
                <img
                  style={{ maxWidth: "100px" }}
                  alt="Clearpath logo"
                  src={"./files/img/clearpath.png"}
                />
              </div>
              <div className="bg-white rounded-lg p-2 m-4 md:w-3/12 justify-center items-center flex">
                <img
                  alt="Edelman logo"
                  style={{ maxWidth: "100px" }}
                  src={"./files/img/edelman.png"}
                />
              </div>
              <div className="bg-white rounded-lg p-2 m-4 md:w-3/12 justify-center items-center flex">
                <img
                  alt="Gazelle logo"
                  style={{ maxWidth: "100px" }}
                  src={"./files/img/gazelle.png"}
                />
              </div>
              <div className="bg-white rounded-lg p-2 m-4 md:w-3/12 justify-center items-center flex">
                <img
                  alt="Greenwald logo"
                  style={{ maxWidth: "100px" }}
                  src={"./files/img/greenwald.png"}
                />
              </div>
              <div className="bg-white rounded-lg p-2 m-4 md:w-3/12 justify-center items-center flex">
                <img
                  alt="Orb logo"
                  style={{ maxWidth: "100px" }}
                  src={"./files/img/orb.jpg"}
                />
              </div>
              <div className="bg-white rounded-lg p-2 m-4 md:w-3/12 justify-center items-center flex">
                <img
                  alt="InCrowd logo"
                  style={{ maxWidth: "100px" }}
                  src={"./files/img/incrowd.jpg"}
                />
              </div>
              <div className="bg-white rounded-lg p-2 m-4 md:w-3/12 justify-center items-center flex">
                <img
                  alt="PDR logo"
                  style={{ maxWidth: "100px" }}
                  src={"./files/img/pdr.jpg"}
                />
              </div>
              <div className="bg-white rounded-lg p-2 m-4 md:w-3/12 justify-center items-center flex">
                <img
                  alt="Quester logo"
                  style={{ maxWidth: "100px" }}
                  src={"./files/img/quester.png"}
                />
              </div>
              <div className="bg-white rounded-lg p-2 m-4 md:w-3/12 justify-center items-center flex">
                <img
                  alt="TLB logo"
                  style={{ maxWidth: "100px" }}
                  src={"./files/img/tlb.jpg"}
                />
              </div>
              <div className="bg-white rounded-lg p-2 m-4 md:w-3/12 justify-center items-center flex">
                <img
                  alt="WWF logo"
                  style={{ maxWidth: "100px" }}
                  src={"./files/img/wwf.jpg"}
                />
              </div>
              <div className="bg-white rounded-lg p-2 m-4 md:w-3/12 justify-center items-center flex">
                <img
                  alt="Yonder logo"
                  style={{ maxWidth: "100px" }}
                  src={"./files/img/yonder.png"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutPGA;
