import React, { useEffect, useState } from "react";
import Pricing4 from "components/pricing/Pricing4";
import AdminSidebar from "components/Sidebar/AdminSidebar";
import styles from "./ManageSubscription.module.css";
import CardHeaderStat from "components/Cards/CardHeaderStat";
import BlueBackground from "components/Cards/BlueBackground";

const ManageSubscription = (props) => {
  const [subscriptionProps, setSubscriptionProps] = useState({
    cards: [],
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [plan, setPlan] = useState("");
  const [trafficCardProps, setTrafficCardProps] = useState({
    statHeader: "PRESENTATIONS LAST MONTH",
    statSubtitle: "",
    statTitle: "",
  });

  const [accountTypeProps, setAccountTypeProps] = useState({
    statHeader: "ACCOUNT TYPE",
    statSubtitle: "",
    statTitle: "",
  });

  const setLocalStorageWithExpiry = (key, value, ttl) => {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  };

  const getLocalStorageWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  };

  useEffect(() => {
    setToken(getLocalStorageWithExpiry("Access_Token"));
    setName(getLocalStorageWithExpiry("Name")); //30 mins

    if (name === null || name === undefined) {
      window.location.href = "/login";
    }
  }, [name]);

  useEffect(() => {
    var postData = new URLSearchParams();
    postData.append("username", getLocalStorageWithExpiry("Name"));
    // POST request with a JSON body using fetch
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      body: postData.toString(),
    };

    requestOptions.headers[
      "Authorization"
    ] = `Bearer ${getLocalStorageWithExpiry("Access_Token")}`;

    fetch(
      `${process.env.REACT_APP_PGA_USER_API_URL}/User/GetUserManagementDashboard`,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Response not OK", response);
      })
      .then((data) => {
        //debugger;

        if (data.isSucces === true) {
          setSubscriptionProps({
            cards: [],
          });
          var cards = [];
          var descriptions = [];
          data.result.subscriptions.forEach(function (subscription) {
            descriptions = [];
            subscription.descriptions.forEach(function (desc) {
              descriptions.push({
                text: desc.description,
                color: "lightBlue",
              });
            });

            cards.push({
              head: subscription.name?.toString(),
              title: "£" + subscription.price?.toString(),
              description: "per month",
              list: descriptions,
              color: subscription.color,
              gradient: false,
              redirectUrl: subscription.redirectUrl,
              showButton: subscription.showButton,
              buttonText: subscription.buttonText?.toString(),
              planCode: subscription.planCode?.toString(),
            });
          });
          setSubscriptionProps({
            cards: cards,
          });

          setTrafficCardProps({
            statHeader: "PRESENTATIONS THIS MONTH",
            statSubtitle: "Reset Date : " + data.result.resetDate,
            statTitle:
              data.result.usedSlideCount + " / " + data.result.allowedFileCount,
          });
          setPlan(data.result.plan);
          setLocalStorageWithExpiry("Plan", data.result.plan, 1000 * 60 * 30); //30 mins
          setAccountTypeProps({
            statHeader: "ACCOUNT TYPE",
            statTitle: data.result.plan
              ? data.result.plan
              : "Not currently subscribed",
            statSubtitle:
              data.result.allowedFileCount + " presentations a month",
          });
        }
      });
  }, [name]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "100vh",
          backgroundColor: "rgb(241,245,249)",
        }}
      >
        <AdminSidebar />
        <div className={styles.manageSub}>
          <h3
            className={styles.title}
            style={{ color: "white", fontSize: "14px" }}
          >
            Manage subscription
          </h3>
          <div className={styles.infoCard}>
            <CardHeaderStat
              gridArea="lastMonth"
              trafficCardProps={trafficCardProps}
            />
            <CardHeaderStat
              gridArea="accType"
              trafficCardProps={accountTypeProps}
            />
          </div>
          <Pricing4
            {...subscriptionProps}
            name={name}
            token={token}
            plan={plan}
          />

          <br />
        </div>
        <BlueBackground />
      </div>
    </>
  );
};

export default ManageSubscription;
