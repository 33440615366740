import React, { Component } from 'react'
import Footer from "components/Footers/Footer.js";
import { Link } from "react-router-dom";

class UserConfirmationResult extends Component {
    state = {
        key : "",        
    }
    componentDidMount= (e) =>  {
        const params = new URLSearchParams(window.location.search);
        var key = params.get('key');
        this.setState(
            {key:key}              
          );
    }
    render() {
        
      
        return (           

          <>
          <section className="relative w-full h-full py-20" >
            <div class="relative h-full text-center container mx-auto px-4 z-3 mb-16">
              <div class="justify-center flex flex-wrap -mx-4">
                <div class="px-12 px-4 relative w-full lg:w-6/12 w-full md:w-8/12">
                  <h1 class="text-4xl font-bold leading-tight">Email address confirmed!</h1>
                    <p class="text-lg opacity-75 pt-2">We have sent your security key to your email. Keep this safe. You will need it to create your authentication token.</p>
                    <p class="text-lg font-bold pt-2">Don't forget to check your spam inbox if you cannot find the email.</p>
                    <p class="text-lg opacity-75 pt-2">Sign in to get started.</p>
                    <Link to="/login">
                      <button
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        padding: "10px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        borderRadius: "5px",
                        marginTop: "5px"
                      }}>
                        Sign in
                      </button>
                    </Link>
                </div>
              </div>
            </div>
            </section>
          <section className="relative w-full h-full py-1 min-h-screen">
          </section>
          <Footer />
        </>
        );
    }
}

export default UserConfirmationResult;

