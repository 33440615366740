import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import "assets/styles/custom.css";
import CookieConsent from "react-cookie-consent";

import Index from "views/Index.js";
import CookiePolicy from "compliance_pages/cookiepolicy.js";
import DataSecurity from "compliance_pages/datasecurity";
import TryFree from "views/index/TryFree";
import Welcome from "views/auth/Welcome";
import ConfirmUser from "views/auth/ConfirmUserComponent"
import UserConfirmationResult from "views/auth/UserConfirmationResult";
import Pricing from "views/Pricing";
import Login from "views/auth/Login"
import Terms from "views/auth/Terms";
import ManageSubscription from "views/admin/ManageSubscription"
import DowngradeSubscription from "views/admin/DowngradeSubscription"

import GettingStarted from "views/admin/GettingStarted"
import ExampleAPI from "views/admin/ExampleAPI"
import AdminHome from "views/admin/AdminHome"
import SelectSubscription from "views/admin/SelectSubscription"
import ChangePassword from "views/auth/ChangePassword"
import Customer from "views/admin/Customer"
import CustomerUsage from "views/admin/CustomerUsage"

import TagManager from "react-gtm-module"
import Tableau from "views/Tableau";
import BearerToken from "views/admin/BearerToken";
import ApiKey from "views/admin/ApiKey"
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import MarketSurveyComponent from "./views/auth/MarketSurveyComponent";

const tagManagerArgs = {
  gtmId: 'GTM-PJKT5KC'
}

TagManager.initialize(tagManagerArgs)

const cookieConsent = localStorage.getItem("PGAcookie")

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Index} />
      <Route path="/cookiepolicy" component={CookiePolicy} />
      <Route path="/login" component={Login} />
      <Route path="/home" component={AdminHome}/>
      <Route path="/managebearertoken" component={BearerToken} />
      <Route path="/managesubscription" component={ManageSubscription} />
      <Route path="/downgradesubscription" component={DowngradeSubscription} />
      <Route path="/usage" component={Customer} />
      <Route path="/customerusage" component={CustomerUsage} />      
      <Route path="/gettingstarted" component={GettingStarted}/>
      <Route path="/exampleapi" component={ExampleAPI}/>
      <Route path="/datasecurity" component={DataSecurity} />
      <Route path="/tryfree" component={TryFree} />
      <Route path="/ConfirmUser" component={ConfirmUser} />
      <Route path="/MarketSurvey" component={MarketSurveyComponent} />
      <Route path="/userConfirmationResult" component={UserConfirmationResult} />
      <Route path="/Welcome" component={Welcome} />
      <Route path="/pricing" component={Pricing} />        
      <Route path="/SelectSubscription" component={SelectSubscription} />     
      <Route path="/changePassword" component={ChangePassword} /> 
      <Route path="/tableau" component={Tableau}/>    
      <Route path="/apikey" component={ApiKey}/>
      <Route path="/terms" component={Terms} />
    <Redirect from="*" to="/" />
  </Switch>

    <TawkMessengerReact propertyId="6318757637898912e967be61" widgetId="1gcbolmhc"/>
    {cookieConsent != null ? <></> : <CookieConsent
      onAccept={(acceptedByScrolling) => {
        localStorage.setItem("PGAcookie", "givemecookiegotyoucookieman")
      }}
      sameSite="lax"
      style={{ background: "#1e293b" }}
      buttonStyle={{ background: "#0ea5e9", color: "white", fontSize: "13px", fontWeight: "bolder"}}
    >
      This website uses cookies to enhance user experience. <a href="/cookiepolicy"><u>Learn more</u></a>
    </CookieConsent>}
  </BrowserRouter>,
  document.getElementById("root")
);
