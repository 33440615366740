import React from "react";
import { BsCheckCircleFill, BsX } from "react-icons/bs";

const CardFullTable = (props) => {
  //const title = props.title;
  const head = props.head;
  const body = props.body;
  const color = props.color;
  //const children = props.children;
  const cardColors = {
    white: "bg-white text-blueGray-700",
    light: "bg-blueGray-200 text-blueGray-700",
    blueGray: "bg-blueGray-900 text-white",
    red: "bg-red-900 text-white",
    orange: "bg-orange-900 text-white",
    amber: "bg-amber-900 text-white",
    emerald: "bg-emerald-900 text-white",
    teal: "bg-teal-900 text-white",
    lightBlue: "bg-lightBlue-900 text-white",
    indigo: "bg-indigo-900 text-white",
    purple: "bg-purple-900 text-white",
    pink: "bg-pink-900 text-white",
  };
  const headColors = {
    white: "bg-blueGray-100 text-blueGray-500 border-blueGray-200",
    light: "bg-blueGray-300 text-blueGray-700 border-blueGray-400",
    blueGray: "bg-blueGray-800 text-blueGray-300 border-blueGray-700",
    red: "bg-red-800 text-red-300 border-red-700",
    orange: "bg-orange-800 text-orange-300 border-orange-700",
    amber: "bg-amber-800 text-amber-300 border-amber-700",
    emerald: "bg-emerald-800 text-emerald-300 border-emerald-700",
    teal: "bg-teal-800 text-teal-300 border-teal-700",
    lightBlue: "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700",
    indigo: "bg-indigo-800 text-indigo-300 border-indigo-700",
    purple: "bg-purple-800 text-purple-300 border-purple-700",
    pink: "bg-pink-800 text-pink-300 border-pink-700",
  };
  // const titleColors = {
  //   white: "text-blueGray-700",
  //   light: "text-blueGray-700",
  //   blueGray: "text-white",
  //   red: "text-white",
  //   orange: "text-white",
  //   amber: "text-white",
  //   emerald: "text-white",
  //   teal: "text-white",
  //   lightBlue: "text-white",
  //   indigo: "text-white",
  //   purple: "text-white",
  //   pink: "text-white",
  // };
  const imageTextColors = {
    white: "text-blueGray-700",
    light: "text-blueGray-700",
    blueGray: "text-white",
    red: "text-white",
    orange: "text-white",
    amber: "text-white",
    emerald: "text-white",
    teal: "text-white",
    lightBlue: "text-white",
    indigo: "text-white",
    purple: "text-white",
    pink: "text-white",
  };
  const iconColors = {
    white: "text-white",
    blueGray: "text-blueGray-500",
    red: "text-red-500",
    orange: "text-orange-500",
    amber: "text-amber-500",
    emerald: "text-emerald-500",
    teal: "text-teal-500",
    lightBlue: "text-lightBlue-500",
    indigo: "text-indigo-500",
    purple: "text-purple-500",
    pink: "text-pink-500",
  };

  const headerFunc = head.map(function (prop, key) {
    return (
      <>
        <th
          key={key}
          className={
            "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-bold text-left " +
            headColors[color]
          }
        >
          {prop}
        </th>
      </>
    );
  });

  const bodyFunc = body.map(function (prop, key) {
    return (
      <tr key={key}>
        {prop.map(function (colProp, colKey) {
          if (typeof colProp === "string") {
            if (colProp === "TRUE") {
              return (
                <td
                  key={colKey}
                  className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                >
                  <div className="flex items-center">
                    <BsCheckCircleFill
                      style={{ color: "green", fontSize: "30px" }}
                    />
                  </div>
                </td>
              );
            }
            if (colProp === "FALSE") {
              return (
                <td
                  key={colKey}
                  className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                >
                  <div className="flex items-center">
                    <BsX style={{ color: "red", fontSize: "30px" }} />
                  </div>
                </td>
              );
            } else {
              return (
                <td
                  key={colKey}
                  className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                >
                  <div className="flex items-center">{colProp}</div>
                </td>
              );
            }
          } else if (colProp.bold) {
            return (
              <td
                key={colKey}
                className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <div className="flex items-center">
                  <span className={"ml-3 font-bold " + +imageTextColors[color]}>
                    {colProp.bold}
                  </span>
                </div>
              </td>
            );
          } else if (colProp.arrow) {
            return (
              <td
                key={colKey}
                className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <div className="flex items-center">
                  <i
                    className={
                      "fas fa-arrow-" +
                      colProp.arrow +
                      " mr-2 " +
                      iconColors[colProp.color]
                    }
                  >
                    {colProp.text}
                  </i>
                </div>
              </td>
            );
          } else if (colProp.progress) {
            return (
              <td
                key={colKey}
                className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <div className="flex items-center">
                  <span className="mr-2">{colProp.text}</span>
                </div>
              </td>
            );
          } else if (colProp.color) {
            return (
              <td
                key={colKey}
                className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <div className="flex items-center">
                  <i
                    className={
                      "fas fa-circle mr-2 " + iconColors[colProp.color]
                    }
                  >
                    {colProp.text}
                  </i>
                </div>
              </td>
            );
          } else if (colProp.image) {
            return (
              <td
                key={colKey}
                className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <div className="flex items-center">
                  <img
                    src={colProp.image}
                    alt={"..."}
                    className="h-12 w-12 bg-white rounded-full border p-1"
                  >
                    <span
                      className={"ml-3 font-bold " + +imageTextColors[color]}
                    >
                      {colProp.text}
                    </span>
                  </img>
                </div>
              </td>
            );
          } else {
            return (
              <td
                key={colKey}
                className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <div className="flex items-center"></div>
              </td>
            );
          }
        })}
      </tr>
    );
  });

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-8 shadow-lg rounded-lg " +
          cardColors[color]
        }
      >
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>{headerFunc}</tr>
            </thead>
            <tbody>{bodyFunc}</tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CardFullTable;
