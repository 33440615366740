import React, { useEffect, useState } from "react";
import Footer from "components/Footers/Footer.js";
import "./MarketSurveyComponent.css";

const MarketSurveyComponent = (props) => {
    const params = new URLSearchParams(window.location.search)
    const [username] = useState(params.get('username'));
    const [uniqueId] = useState(params.get('uniqueId'));
    const [professions, setProfessions] = useState([]);
    const [roles, setRoles] = useState([]);
    const [goals, setGoals] = useState([]);
    const [profession, setProfession] = useState('');
    const [otherProfession, setOtherProfession] = useState('');
    const [role, setRole] = useState('');
    const [otherRole, setOtherRole] = useState('');
    const [otherGoal, setOtherGoal] = useState('');
    const [requestGotError, setRequestGotError] = useState(false);
    const [requestErrorMessage, setRequestErrorMessage] = useState('');
    const [goal, setGoal] = useState('');
    const [initialized, setInitialized] = useState(false);
    const submitRef = React.useRef(null);
    const otherProfessionRef = React.useRef(null);
    const otherRoleRef = React.useRef(null);
    const otherGoalRef = React.useRef(null);

    const isValidForm = () => {
        let prof = profession !== "" && profession !== "Other" ? profession : otherProfession;
        let rl = role !== "" && role !== "Other" ? role : otherRole;
        let gl = goal !== "" && goal !== "Other" ? goal : otherGoal;
        return prof !== "" && rl !== "" && gl !== "";
    }

    useEffect(() => {
        if (!initialized) {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            };

            fetch(
                `${process.env.REACT_APP_PGA_USER_API_URL}/User/categories/`,
                requestOptions
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data.isSucces === true) {
                        console.log(data)
                        setProfessions(data.result.options['Profession']);
                        setRoles(data.result.options['Role']);
                        setGoals(data.result.options['Goal']);
                    } else {
                        setRequestGotError(true);
                        setRequestErrorMessage(data.message);
                        console.log(data);
                    }
                });

            setInitialized(true);
        }

        submitRef.current.className = isValidForm() ? "button-submit enabled" : "button-submit disabled";

    }, [username, profession, role, goal, otherProfession, otherRole, otherGoal, initialized, professions, roles, goals]);

    const formSubmit = (event) => {
        event.preventDefault();

        if (isValidForm()) {
            const requestOptions = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ profession: profession, role: role, goal: goal }),
            };

            fetch(
                `${process.env.REACT_APP_PGA_USER_API_URL}/User/UpdateUserInfo/` + uniqueId,
                requestOptions
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data.isSucces === true) {
                        window.location.href =
                            "/userConfirmationResult?key=" + data.result.key;
                    } else {
                        setRequestGotError(true);
                        setRequestErrorMessage(data.message);
                        console.log(data);
                    }
            });
        }
    }

    const selectElementByClass = (event, className) => {
        document.querySelectorAll(className).forEach(element =>
            element.style.backgroundColor = ""
        );
        event.target.style.backgroundColor = "lightsteelblue"
    }

    const clear = (e) => {
        e.current.style.visibility = "hidden";
        e.current.value = "";
    }

    const handleControlSelection = (e, className, ref) => {
        e.preventDefault();
        selectElementByClass(e, className);
        clear(ref);
    }

    const handleOtherSelection = (e, className, ref) => {
        e.preventDefault();
        selectElementByClass(e, className);
        ref.current.style.visibility = "visible";
        ref.current.focus()
    }

    const handleProfessionSelection = (e, val, className, ref) => {
        handleControlSelection(e, className, ref);
        setProfession(val);
        setOtherProfession("");
    }

    const handleRoleSelection = (e, val, className, ref) => {
        handleControlSelection(e, className, ref);
        setRole(val);
        setOtherRole("");
    }

    const handleGoalSelection= (e, val, className, ref) => {
        handleControlSelection(e, className, ref);
        setGoal(val);
        setOtherGoal("");
    }

    return (
        <>
            <section className="relative w-full h-full py-20" >
                <div className="relative h-full text-center container mx-auto px-4 z-3 mb-16">
                    <div className="justify-center flex flex-wrap -mx-4">
                        <div className="px-12 px-4 relative w-full lg:w-6/12 w-full md:w-8/12">
                            <form onSubmit={formSubmit}>
                                <h1 className="text-4xl font-bold leading-tight">Welcome {username}</h1>
                                <br />
                                <p className="text-lg opacity-75 pt-2">What are your goals?</p>
                                <div className="grid-goal">
                                    {goals.map(gl =>
                                        <button className="button grid-item goal"
                                            onClick={(e) => {
                                                handleGoalSelection(e, gl, '.goal', otherGoalRef);
                                            }}
                                            key={gl}
                                            type="button">
                                            {gl}
                                        </button>
                                    )}
                                    <button className="button grid-item goal span-goal"
                                        onClick={(e) => { handleOtherSelection(e, '.goal', otherGoalRef); setGoal('Other'); }}
                                        type="button">
                                        Other
                                    </button>
                                </div>
                                <div className="relative w-full mb-3">
                                    <input
                                        style={{ visibility: "hidden", marginTop: "10px" }}
                                        type="text"
                                        ref={otherGoalRef}
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
                                        name="otherGoal"
                                        id="otherGoal"
                                        value={otherGoal}
                                        onChange={(e) => { setOtherGoal(e.target.value); setGoal(e.target.value); }}
                                    />
                                </div>
                                <p className="text-lg opacity-75 pt-2">What's the main thing you'll be using PowerPoint Generator API for?</p>
                                <div className="grid-profession">
                                    { professions.map( prf =>
                                        <button className="button grid-item profession"
                                            onClick={(e) => {
                                                handleProfessionSelection(e, prf, '.profession', otherProfessionRef);
                                            }}
                                            key={prf}
                                            type="button">
                                            {prf}
                                        </button>
                                        )
                                    }
                                    <button className="button grid-item profession"
                                        onClick={(e) => { handleOtherSelection(e, '.profession', otherProfessionRef); setProfession('Other'); }}
                                        type="button">
                                        Other
                                    </button>
                                </div>
                                <div className="relative w-full mb-3">
                                    <input
                                        style={{ visibility: "hidden", marginTop: "10px"  }}
                                        type="text"
                                        ref={otherProfessionRef}
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
                                        name="otherProfession"
                                        id="otherProfession"
                                        value={otherProfession}
                                        onChange={(e) => { setOtherProfession(e.target.value); setProfession(e.target.value); }}
                                    />
                                </div>
                                <p className="text-lg opacity-75 pt-2">What best describes your role?</p>
                                <div className="grid-role">
                                    {roles.map(rl => 
                                        <button className="button grid-item role"
                                            onClick={(e) => {
                                                handleRoleSelection(e, rl, '.role', otherRoleRef);
                                            }}
                                            key={rl}
                                            type="button">
                                            {rl}
                                        </button>
                                    )}
                                    <button className="button grid-item role spans"
                                        onClick={(e) => { handleOtherSelection(e, '.role', otherRoleRef); setRole('Other'); }}
                                        type="button">
                                        Other
                                    </button>
                                </div>
                                <div className="relative w-full mb-3">
                                    <input
                                        style={{ visibility: "hidden", marginTop: "10px" }}
                                        type="text"
                                        ref={otherRoleRef}
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear"
                                        name="otherRole"
                                        id="otherRole"
                                        value={otherRole}
                                        onChange={(e) => { setOtherRole(e.target.value); setRole(e.target.value); }}
                                    />
                                </div>
                                <button ref={submitRef} className="button-submit disabled">Done</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default MarketSurveyComponent;
