import React, { useEffect } from "react";
import Register from "components/Users/Register";
import Footer from "components/Footers/Footer.js";

function TryFree() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <section className="relative w-full h-full py-10">
        <div class="relative h-full text-center container mx-auto px-4 z-3 mb-16">
          <div class="justify-center flex flex-wrap -mx-4">
            <div class="px-12 px-4 relative w-full lg:w-6/12 w-full md:w-8/12">
              <h1 class="text-4xl font-bold leading-tight">
                Let us handle PowerPoints
              </h1>
              <p class="text-lg opacity-75 pt-2">
                Start free - <i>No credit card required</i>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="relative w-full h-full py-1 min-h-screen">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <Register />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default TryFree;
