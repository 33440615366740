/*eslint-disable*/
import React from "react";
import IndexNavbar from "components/Navbars/Navbar.js";
import Footer from "components/Footers/Footer.js";
import InfoAreaIconTitleList from "@notus-pro/react/InfoAreaIconTitleList";
import exitIntent from "exit-intent";
import Popup from "components/popups/Popup.js";
import { useState } from "react";
import { useEffect } from "react";
import PGALandingPricing from "components/pricing/PGALandingPricing";

import { Link } from "react-router-dom";
import Register from "components/Users/Register.js";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import textJson from "../assets/json/text-boxes.json";
import MediaPlayerTeamInfo from "components/MediaPlayerTeamInfo";
//import MediaPlayerTeamInfo from "@notus-pro/react/MediaPlayerTeamInfo";
import chartsJson from "../assets/json/data-charts.json";
import dataJson from "../assets/json/data-tables.json";
import picturesJson from "../assets/json/pictures-tables.json";
import Comparison from "./index/Comparison/Comparison";
import AutomatePowerPoint from "./index/AutomatePowerPoint";
import HowItWorks from "./index/HowItWorks";
import PowerfulEasy from "./index/PowerfulEasy";
import WhatsIncluded from "./index/WhatsIncluded";
import AboutPGA from "./index/AboutPGA";
import Pricing from "./index/Pricing";
import FinalCTA from "./index/FinalCTA";
var collapsible = require("collapsible");

const props = {
  icon: "fas fa-toolbox",
  color: "lightBlue",
  title: "The power of PowerPoint Generator API",
  description: "",
  list: [
    { icon: "fas fa-check", text: "Automate PowerPoint document creation" },
    {
      icon: "fas fa-check",
      text: "Template driven, reusable and customisable",
    },
    {
      icon: "fas fa-check",
      text: "Design your templates in Powerpoint and stay on brand",
    },
    {
      icon: "fas fa-check",
      text: "Fill any PowerPoint objects from  charts to text boxes with your data",
    },
    {
      icon: "fas fa-check",
      text: "Automate updating dates, project and client names across all slides with tagging",
    },
    {
      icon: "fas fa-check",
      text: "Continue working on completely editable and native PowerPoint presentations",
    },
    {
      icon: "fas fa-check",
      text: "Available at the click of a button, instantly and at a low cost",
    },
  ],
};

let options = {
  maxLength: 80,
};

export default function Index() {
  const [buttonPopup, setButtonPopup] = useState(false);
  const [name, setName] = useState("");
  const [signoutVisible, setSignoutVisible] = React.useState(false);
  const [signinVisible, setSigninVisible] = React.useState(true);

  const getLocalStorageWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  };

  useEffect(() => {
    console.log("setToken 1 debug. name:" + name);
    console.log("setToken 1 debug. signinVisible:" + signinVisible);
    console.log("setToken 1 debug. signoutVisible:" + signoutVisible);

    setName(getLocalStorageWithExpiry("Name")); //30 mins

    console.log("setToken 2 debug. name:" + name);
    if (name === null || name === undefined) {
      setSignoutVisible(false);
      setSigninVisible(true);
    } else {
      setSignoutVisible(true);
      setSigninVisible(false);
    }
  }, [name]);

  // Initialise
  const removeExitIntent = exitIntent({
    threshold: 5,
    maxDisplays: 2,
    eventThrottle: 20,
    onExitIntent: () => {
      /**
       * Set to TRUE to turn on exit intent pop up
       */
      setButtonPopup(false);
    },
  });

  const str = collapsible.stringify(textJson, options);

  const mediaProps = {
    defaultOpened: 0,
    items: [
      {
        title: "Editing text boxes",
        image: "./files/img/text_boxes.png",
        description: str,
        color: "indigo",
        button: { children: "See Our Docs" },
      },
      {
        title: "Adding data to charts",
        image: "./files/img/add-data-to-chart.jpg",
        description: collapsible.stringify(chartsJson, options),
        color: "indigo",
        button: { children: "See Our Docs" },
      },
      {
        title: "Adding data to tables",
        image: "./files/img/data-to-table.png",
        description: collapsible.stringify(dataJson, options),
        color: "indigo",
        button: { children: "See Our Docs" },
      },
      {
        title: "Replacing placeholder pictures",
        image: "./files/img/image.png",
        description: collapsible.stringify(picturesJson, options),
        color: "indigo",
        button: { children: "See Our Docs" },
      },
    ],
  };

  return (
    <>
      <GoogleReCaptchaProvider
        container={{
          element: "googlebadge",
          parameters: {
            badge: "bottomleft",
          },
        }}
        reCaptchaKey="6LdYGZUeAAAAAKAUXfhLzzZ8Ev0NMoH86J3MKkVr"
      >
        <div id="googlebadge" style={{zIndex:1000, position: "relative"}} />
        <IndexNavbar fixed />
        <AutomatePowerPoint signinVisible={signinVisible} />

        <section
          className="mt-48 md:mt-40 pb-32 relative bg-blueGray-200"
          id="product"
          style={{zIndex: 999}}
        >
          <HowItWorks />

          <PowerfulEasy />

          <MediaPlayerTeamInfo {...mediaProps} />

          <div
            className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
              style={{marginBottom:-1}}
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
          <div className="container mx-auto overflow-hidden pb-20 flex justify-center items-center pt-20">
            <InfoAreaIconTitleList {...props} />
          </div>

          <WhatsIncluded />

          <Comparison />
        </section>

        <AboutPGA />

        <Pricing />

        <FinalCTA />
        <Popup trigger={buttonPopup} setTrigger={setButtonPopup}></Popup>

        <Footer />
      </GoogleReCaptchaProvider>
    </>
  );
}
