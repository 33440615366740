import React, { Component } from "react";
import { hotjar } from "react-hotjar";
class ConfirmUser extends Component {
  state = {
    email: "",
    password: "",
    confirmPassword: "",
    postId: "",
    emailValid: true,
    passwordValid: true,
    passwordsSame: true,
    confirmEmail: false,
    requestGotError: false,
    requestErrorMessage: "",
    isConfirmed: false,
    key: "",
    isFreeTrial: false,
  };
  componentDidMount = (e) => {
    //debugger;
    hotjar.initialize("2739117", 6);

    //debugger;

    const params = new URLSearchParams(window.location.search);
    var uniqueId = params.get("uniqueId");

    this.setState({ isConfirmed: false });
    var postData = new URLSearchParams();
    postData.append("uniqueId", uniqueId);
    // POST request with a JSON body using fetch
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      body: postData.toString(),
    };

    //debugger;

    fetch(
      `${process.env.REACT_APP_PGA_USER_API_URL}/User/CheckConfirmUser`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        //debugger;
        if (data.isSucces === true) {
            window.location.href =
                "/MarketSurvey?uniqueId=" + uniqueId + "&username=" + data.result.username;
        } else {
          this.setState({
            confirmEmail: false,
            requestGotError: true,
            requestErrorMessage: data.message,
          });
        }
      })
      .catch((error) => {
        //debugger;
      });
  };
  

  changeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validateForm = () => {
    const { password, confirmPassword } = this.state;

    var passwordValidTemp = true;
    var passwordsSameTemp = true;

    //Reset validating flags
    this.setState({
      emailValid: true,
      passwordValid: true,
      passwordsSame: true,
    });

    if (password.length < 6) {
      passwordValidTemp = false;
      this.setState({ passwordValid: false });
    }

    if (password !== confirmPassword) {
      passwordsSameTemp = false;
      this.setState({ passwordsSame: false });
    }

    if (!passwordValidTemp || !passwordsSameTemp) return false;

    return true;
  };

  confirmUser = (e) => {
    e.preventDefault();

    const { password } = this.state;

    if (!this.validateForm(this.state)) return;

    const params = new URLSearchParams(window.location.search);
    var uniqueId = params.get("uniqueId");
    var postData = new URLSearchParams();
    postData.append("uniqueId", uniqueId);
    postData.append("password", password);
    // POST request with a JSON body using fetch
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      body: postData.toString(),
    };

    //debugger;

    fetch(
      `${process.env.REACT_APP_PGA_USER_API_URL}/User/ConfirmUser`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        //debugger;
        if (data.isSucces === true) {
          window.location.href =
              "/MarketSurvey?uniqueId=" + uniqueId + "&username=" + data.result.username;
        } else {
          this.setState({
            confirmEmail: false,
            requestGotError: true,
            requestErrorMessage: data.message,
          });
        }
      });
  };

  render() {

    return (
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>Complete user registration</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmUser;
