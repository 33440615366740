import React from "react";
import ConfirmUser from "components/Users/ConfirmUser";

function ConfirmUserComponent() {
  return (    
      <ConfirmUser />
  );
}

export default ConfirmUserComponent;
