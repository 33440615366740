import React from "react";
import PropTypes from "prop-types";

export default function CardApiKey({userApiKey}) {
const copyKey =()=>{
  const input = document.getElementById("copy-key-text-box");
  input.focus();
  input.select();
  window.navigator.clipboard.writeText(input.value)
}

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
        <div className="flex-auto p-4">
          <div className="flex flex-wrap">
            <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
              <h5 className="text-black-400 font-bold text-xs">
                Your API Key - Please keep this safe
              </h5>
            </div>
            
          </div>
          <div style={{position: "relative"}}>
          <textarea id="copy-key-text-box" value={userApiKey?userApiKey[0]['key']:""} readOnly rows={1} style={{width: "100%", marginTop: "10px", borderRadius: "4px", resize: "none"}} />
          <button className="CopyButton" onClick={copyKey}>Copy</button>
          </div>
        </div>
      </div>
    </>
  );
}

CardApiKey.defaultProps = {
  statSubtitle: "Traffic",
  statTitle: "350,897",
  statArrow: "up",
  statPercent: "3.48",
  statPercentColor: "text-emerald-500",
  statDescripiron: "Since last month",
  statIconName: "far fa-chart-bar",
  statIconColor: "bg-red-500",
};

CardApiKey.propTypes = {
  statSubtitle: PropTypes.string,
  statTitle: PropTypes.string,
  statArrow: PropTypes.oneOf(["up", "down"]),
  statPercent: PropTypes.string,
  // can be any of the text color utilities
  // from tailwindcss
  statPercentColor: PropTypes.string,
  statDescripiron: PropTypes.string,
  statIconName: PropTypes.string,
  // can be any of the background color utilities
  // from tailwindcss
  statIconColor: PropTypes.string,
};
