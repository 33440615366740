import React from "react";
import Register from "components/Users/Register";

const AutomatePowerPoint = (props) => {
  const { signinVisible } = props;
  return (
    <section
      id="early-access"
      className="header relative pt-16 items-center flex "
    >
      <div
        className="container mx-auto items-center justify-center flex flex-wrap"
        id="register"
      >
        <div className="w-full sm:w-6/12 xl:w-6/12 px-4">
          <div className="pt-32 sm:pt-0">
            <h2 className="font-semibold text-4xl text-blueGray-600">
              Automate PowerPoint Generation
            </h2>
            <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
              Fill template PowerPoint documents with your data. Send a JSON
              payload, receive a populated PowerPoint document.
            </p>
            <div className="mt-12">
              <div className="">
                {signinVisible ? (
                  <Register />
                ) : (
                  <a
                    href="/home"
                    target="_self"
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    My Admin Area
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>

        <img
          className="top-48 b-auto right-0 pt-16 w-12/12 md:w-12/12 lg:w-6/12 xl:w-7/12 md:-mt-48 lg:-mt-48 sm:mt-0 max-h-860px "
          src={"./files/img/PGA step by step.png"}
          alt="A diagram showing how a JSON payload can transform into a populated PowerPoint document"
        />
      </div>
    </section>
  );
};

export default AutomatePowerPoint;
