import styles from "./CardBearerToken.module.css";
const copyBearerToken = (props) => {
    const { exampleToken } = props;

    if (exampleToken) {
        navigator.clipboard.writeText(exampleToken)
            .then(() => {
                alert('Copied to clipboard');
            })
            .catch(err => {
                console.error('Failed to copy to clipboard:', err);
            });
    }
}
const CardBearerTokenDisplay = (props) => {

  return (
    <div
      class="rounded overflow-hidden shadow-lg"
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        width: "100%",
        paddingInline: "10px",
        backgroundColor: "white",
      }}
    >
      <h1
        style={{
          opacity: "50%",
          fontSize: 12,
          fontWeight: "bold",
        }}
      >
        YOUR BEARER TOKEN
      </h1>
      <h1
        style={{
          fontWeight: "bold",
          fontSize: 18,
          paddingTop: "5px"
        }}
      >
        Your bearer token will only be shown once
      </h1>
      <textarea value={props.exampleToken} readOnly rows={5}/>
      <h1
        style={{
          opacity: "50%",
          fontSize: 12,
          fontWeight: "bold",
        }}
      >
        Valid until:
      </h1>
      <h1
        style={{
          opacity: "50%",
          fontSize: 12,
          fontWeight: "bold",
        }}
      >
        {props.expiryDate ? props.expiryDate : ""}
    </h1>
     <button className={styles.button} onClick={() => copyBearerToken(props)}>
        COPY TO CLIPBOARD
    </button>
    </div>
  );
};

export default CardBearerTokenDisplay;
