import styles from "./Card.module.css";
import {AiOutlinePaperClip} from "react-icons/ai";
import { MdContentCopy } from "react-icons/md";
const CardChartExample = (props) => {
  const exampleJson = {
    "presentation": {
      "template": "slides_as_template.pptx",
      "export_version": "Pptx2010",
      "slides": [
        {
          "type": "slide",
          "slide_index": 1,
          "shapes": [
            {
              "name": "Chart 14",
              "title": "Automating shape types",
              "data": [
                [
                  "Charts",
                  "Tables",
                  "Pictures",
                  "Textboxes"
                ],
                [
                "Shape types used in January"
                ],
                [
                  11, 
                  15, 
                  4, 
                  7
                ]
              ]
            }
          ]
        }
      ]
    }
  }
  

  return (
    <div
      class="shadow-lg"
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        paddingInline: "10px",
        gridArea: "chartDoc",
        height: "fit-content",
        paddingBottom: "25px",
        borderRadius: "0.25rem",
        overflow: "hidden",
        backgroundColor: "white",
      }}
    >
      <h1 className={styles.cardTitle}>Updating charts</h1>
      <h1
        style={{
          fontWeight: "bold",
          fontSize: 18,
          paddingTop: "5px",
        }}
      >
        Edit insert your data into charts on your template slides
      </h1>
      <div
        style={{
          paddingLeft: "5px",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
        }}
      >
        <p2
          style={{
            opacity: "50%",
            fontWeight: "normal",
            fontSize: 14,
            paddingTop: "20px",
          }}
        >
          <span
            style={{
              backgroundColor: "darkGreen",
              fontWeight: "bold",
              color: "white",
              borderRadius: "10px",
              padding: "5px",
            }}
          >
            POST
          </span>
          <span style={{ paddingLeft: "20px" }}>          
          {process.env.REACT_APP_PGA_GENERATOR_API_URL}/
            <span style={{ fontWeight: "bold" }}>generator/create</span>
          </span>
        </p2>
        <p2
          style={{
            paddingTop: "15px",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        ></p2>
        <p2
          style={{
            paddingTop: "15px",
            fontSize: "14px",
            opacity: "75%",
          }}
        ></p2>
        <p2
          style={{
            paddingTop: "15px",
            fontSize: "16px",
            fontWeight: "bold",
            opacity: "75%",
          }}
        >
          Parameters
        </p2>
        <p2
          style={{
            paddingTop: "15px",
            fontSize: "14px",
            fontWeight: "bold",
            opacity: "75%",
          }}
        >
          Header
        </p2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 4fr",
            gridTemplateRows: "1fr",
            gridTemplateAreas: "header type description",
            padding: "5px",
          }}
        >
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            Content-Type
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            string
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            multipart/form-data
          </p2>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 4fr",
            gridTemplateRows: "1fr",
            gridTemplateAreas: "header type description",
            padding: "5px",
          }}
        >
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            Authorization
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            string
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            Bearer authentication token
          </p2>
        </div>

        <p2
          style={{
            paddingTop: "15px",
            fontSize: "14px",
            fontWeight: "bold",
            opacity: "75%",
          }}
        >
          Body
        </p2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 4fr",
            gridTemplateRows: "1fr",
            gridTemplateAreas: "header type description",
            padding: "5px",
          }}
        >
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            files
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            object
          </p2>
          <a href="./files/slides_as_template.pptx" download>
            <button
              style={{
                paddingTop: "5px",
                fontSize: "14px",
                opacity: "70%",
                backgroundColor: "lightGray",
                borderRadius: "5px",
                padding: "5px",
                alignSelf: "center",
                color: "black",
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <AiOutlinePaperClip/> slides_as_template.pptx
            </button>
          </a>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 4fr",
            gridTemplateRows: "1fr",
            gridTemplateAreas: "header type description",
            padding: "5px",
          }}
        >
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            jsonData
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            string
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
              height: "fit-content",
              overflow: "scroll",
            }}
          >
              <button
              onClick={() => {
                navigator.clipboard.writeText(JSON.stringify(exampleJson, null, 2));
              }}
              style={{
                  position: "static",
                  float: "right",
              }}
            >
              {" "}
              <MdContentCopy />
            </button>
            <pre>{JSON.stringify(exampleJson, null, 2)}</pre>
          </p2>
        </div>
      </div>
    </div>
  );
};

export default CardChartExample;
