/*eslint-disable*/
import React from "react";

import IndexNavbar from "components/Navbars/Navbar.js";
import Footer from "components/Footers/Footer.js";
import InfoAreaIconTitleList from "@notus-pro/react/InfoAreaIconTitleList";

import exampleJson from "../assets/json/example.json";

const props = {
  icon: "fas fa-toolbox",
  color: "lightBlue",
  title: "The power of PowerPoint Generator API",
  description: "",
  list: [
    { icon: "fas fa-check", text: "Automate PowerPoint document creation" },
    {
      icon: "fas fa-check",
      text: "Template driven, reusable and customisable",
    },
    {
      icon: "fas fa-check",
      text: "Design your templates in Powerpoint and stay on brand",
    },
    {
      icon: "fas fa-check",
      text: "Fill any PowerPoint objects from  charts to text boxes with your data",
    },
    {
      icon: "fas fa-check",
      text: "Automate updating dates, project and client names across all slides with tagging",
    },
    {
      icon: "fas fa-check",
      text: "Continue working on completely editable and native PowerPoint presentations",
    },
    {
      icon: "fas fa-check",
      text: "Available at the click of a button, instantly and at a low cost",
    },
  ],
};

export default function DataSecurity() {
  return (
    <>
      <IndexNavbar fixed />
      <section className="bg-white overflow-hidden">
        <div className="container mx-auto pb-32  pt-12">
          <div className="container mx-auto px-4 pb-6" id="cookies">
            <div className="justify-center text-left flex flex-wrap mt-24">
              <div className="w-full md:w-12/12 px-12 md:px-4">
                <h2 className="font-semibold text-4xl text-blueGray-600">
                  Data security
                </h2>
                <div class="mb-8 text-base text-blueGray-500">                
                  <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                    <b>API security</b>
                  </p>
                  <p class="mb-4 text-base text-blueGray-500">
                    HTTPS is enabled for all calls to PowerPoint Generator API
                    and JSON Web Tokens (JWT) to establish secure
                    server-to-server authentication. To prevent unauthorized
                    account access we suggest using a strong passphrase for your
                    PowerPoint Generator user account. We also require a bearer
                    token to authenticate all requests. SSL database connections
                    are enabled to protect sensitive data transmitted to and
                    from the application.
                  </p>

                  <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                    <b>Customer information</b>
                  </p>

                  <p class="mb-4 text-base text-blueGray-500">
                    PowerPoint Generator API customer data is stored in a
                    dedicated access-controlled database. The database requires
                    a unique username and password that is only valid for that
                    specific database and is unique to the PGA application.
                  </p>
                  <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                    <b>GDPR compliance</b>
                  </p>
                  <p class="mb-4 text-base text-blueGray-500">
                    We are committed to GDPR compliance across our services. Our
                    infrastructure provider Amazon Web Services complies with
                    European Union’s General Data Protection Regulations and is
                    part of EU-US Privacy Shield. We also provide custom
                    deployments in European regions provided by Amazon Web
                    Services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
