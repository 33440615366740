import React, { Component } from "react";
class DowngradeSubscription extends Component {
  state = {
   
   };


  componentDidMount = (e) => {

      debugger;

      const getLocalStorageWithExpiry = (key) => {
          const itemStr = localStorage.getItem(key);
          if (!itemStr) {
              return null;
          }
          const item = JSON.parse(itemStr);
          const now = new Date();

          if (now.getTime() > item.expiry) {
              localStorage.removeItem(key);
              return null;
          }
          return item.value;
      };

    const params = new URLSearchParams(window.location.search);
    var planCode = params.get("planCode");

    debugger;
    var postData = new URLSearchParams();
    postData.append("planCode", planCode);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      body: postData.toString(),
      };

      requestOptions.headers[
          "Authorization"
      ] = `Bearer ${getLocalStorageWithExpiry("Access_Token")}`;

    debugger;

    fetch(
        `${process.env.REACT_APP_PGA_USER_API_URL}/User/DowngradeSubscription`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        debugger;
        if (data.isSucces === true) {
            window.location.href = "/gettingstarted";
        } else {
            window.location.href = "/gettingstarted";
        }
      })
      .catch((error) => {
        debugger;
      });
  };
  
  render() {

    return (
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>Downgrade subscription is in progress</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DowngradeSubscription;
