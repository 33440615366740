import React from "react";
import { hotjar } from "react-hotjar";
import "./ZohoPricing.css";
import { useEffect } from "react";
import useMediaQuery from "hooks/useMediaQuery";
const ZohoPricing = (props) => {
  const screen = useMediaQuery("(min-width: 1297px)");
  useEffect(() => {
    hotjar.initialize("2739117", 6);
    window.InitZFWidget();
  }, []);

  //refresh the ZF widget here as for some reason the iframe linked doesn't reset height
  //this causes css issues
  useEffect(() => {
    hotjar.initialize("2739117", 6);
    window.InitZFWidget();
  }, [screen]);

  return (
    <>
      {screen ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div style={{ width: "641", display: "flex" }}>
            <iframe
              src="../pricing/free-pricing.html"
              width="100%"
              align="right"
              scrolling="no"
              frameborder="0"
              allowtransparency="true"
              title="Pptx Free Subscription"
              height="767"
            ></iframe>
          </div>
          <div
            id="zf-widget-root-id"
            style={{ float: "left", width: "60%" }}
          ></div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div style={{ width: "641", display: "flex" }}>
            <iframe
              src="../pricing/free-pricing.html"
              width="100%"
              align="right"
              scrolling="no"
              frameborder="0"
              allowtransparency="true"
              title="Pptx Free Subscription"
              height="767"
            ></iframe>
          </div>
          <div
            id="zf-widget-root-id"
            style={{ float: "left", width: "60%", display: "block" }}
          ></div>
        </div>
      )}
    </>
  );
};

export default ZohoPricing;
