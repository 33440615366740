import React from "react";
import PropTypes from "prop-types";
import styles from "./CardTable.module.css"
// components

export default function CardTable(props) {
  const { color } = props;
  return (
    <>
          {/* Projects table */}
          <table className={"items-center w-full bg-transparent border-collapse" + (color === "light" ? " bg-white" : " bg-lightBlue-900")}>
            <thead className={styles.customerTableThead}>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Email
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Date
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Subscription
                </th>
                <th
                className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                (color === "light"
                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                }
                >
                Profession
                </th>
                <th
                className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                (color === "light"
                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                }
                >
                    Role
                </th>
                <th
                className={
                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                (color === "light"
                ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                }
                >
                    Goal
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Successful API Calls
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Unsuccessful API Calls
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Total API Calls
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Presentations
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Slides
                </th>
              </tr>
            </thead>
            <tbody>
              {props.data.map((val, key) => {
                return (
                  <tr
                    key={key}
                    
                  >
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <a href={"/customerusage?email=" + val.email} className={styles.email}>{val.email}</a>
                    </td>
                    <td className="border-t-0 text-white px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {val.date}
                    </td>
                    <td className="border-t-0 px-6 text-white align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {val.subscription}
                        </td>
                        <td className="border-t-0 px-6 text-white align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            {val.profession}
                        </td>
                        <td className="border-t-0 px-6 text-white align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            {val.role}
                        </td>
                        <td className="border-t-0 px-6 text-white align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            {val.goal}
                        </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <a href={"/customerusage?email=" + val.email} className={styles.email}>{val.apiCallCount}</a>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <a href={"/customerusage?email=" + val.email} className={styles.email}>{val.failedApiCallCount}</a>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <a href={"/customerusage?email=" + val.email} className={styles.email}>{val.totalApiCallCount}</a>
                    </td>
                    <td className="border-t-0 text-white px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {val.presentationCount}
                    </td>
                    <td className="border-t-0 px-6 text-white align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {val.slideCount}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
      
    </>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
