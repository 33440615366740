import styles from "./Card.module.css";
const CardUsingDoc = (props) => {
  return (
    <div
      class="shadow-lg"
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        paddingInline: "10px",
        gridArea: "authDoc",
        height: "fit-content",
        paddingBottom: "25px",
        borderRadius: "0.25rem",
        overflow: "hidden",
        backgroundColor: "white",
      }}
    >
      <h1 className={styles.cardTitle}>USING YOUR BEARER TOKEN</h1>
      <h1
        style={{
          fontWeight: "bold",
          fontSize: 18,
          paddingTop: "5px",
        }}
      >
        How you will use your token to authenticate calls
      </h1>
      <div
        style={{
          paddingLeft: "5px",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
        }}
      >
        <p2
          style={{
            opacity: "50%",
            fontWeight: "normal",
            fontSize: 14,
            paddingTop: "20px",
          }}
        >
          <span
            style={{
              backgroundColor: "darkGreen",
              fontWeight: "bold",
              color: "white",
              borderRadius: "10px",
              padding: "5px",
            }}
          >
            POST
          </span>
          <span style={{ paddingLeft: "20px" }}>
            {process.env.REACT_APP_PGA_GENERATOR_API_URL}/
            <span style={{ fontWeight: "bold" }}>generator/create</span>
          </span>
        </p2>
        <p2
          style={{
            paddingTop: "15px",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        ></p2>
        <p2
          style={{
            paddingTop: "15px",
            fontSize: "14px",
            opacity: "75%",
          }}
        ></p2>
        <p2
          style={{
            paddingTop: "15px",
            fontSize: "16px",
            fontWeight: "bold",
            opacity: "75%",
          }}
        >
          Parameters
        </p2>
        <p2
          style={{
            paddingTop: "15px",
            fontSize: "14px",
            fontWeight: "bold",
            opacity: "75%",
          }}
        >
          Header
        </p2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 4fr",
            gridTemplateRows: "1fr",
            gridTemplateAreas: "header type description",
            padding: "5px",
          }}
        >
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            Content-Type
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            string
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            multipart/form-data 
          </p2>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 4fr",
            gridTemplateRows: "1fr",
            gridTemplateAreas: "header type description",
            padding: "5px",
          }}
        >
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            Authorization
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            string
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            Bearer authentication token
          </p2>
        </div>

        <p2
          style={{
            paddingTop: "15px",
            fontSize: "14px",
            fontWeight: "bold",
            opacity: "75%",
          }}
        >
          Body
        </p2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 4fr",
            gridTemplateRows: "1fr",
            gridTemplateAreas: "header type description",
            padding: "5px",
          }}
        >
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            files
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            object
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            PPTX file used as styling and layout template
          </p2>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 4fr",
            gridTemplateRows: "1fr",
            gridTemplateAreas: "header type description",
            padding: "5px",
          }}
        >
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            jsonData
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            string
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            JSON payload which contains the presentation definition
          </p2>
        </div>
      </div>
    </div>
  );
};

export default CardUsingDoc;
