import React from "react";
import TableauQuiz from "./TableauQuiz";

const TabHeader = (props) => {
  return (
    <section
      id="early-access"
      className="header relative pt-16 items-center flex-col"
    >
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h2 className="font-semibold text-4xl">
          Branded, editable PowerPoint exports from your Tableau dashboards
        </h2>
        <h2 className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
        Say hello to the new, easy way to generate customizable PowerPoint exports directly from your Tableau dashboards.
        </h2>
        <TableauQuiz />
      </div>
    </section>
  );
};

export default TabHeader;
