import React from "react";
import styles from "./ComparisonTable.module.css";
import CardFullTable from "./CardFullTable.jsx";

const ComparisonTable = (props) => {
  const cardfulltable = {
    color: "white",
    head: ["", "PowerPoint Generator API", "Python-pptx", "PptxGenJs"],
    body: [
      ["Hosted web service", "TRUE", "FALSE", "FALSE"],

      ["Populate existing presentations", "TRUE", "TRUE", "FALSE"],

      ["Populate existing objects", "TRUE", "TRUE", "FALSE"],

      ["Populate custom tags", "TRUE", "FALSE", "FALSE"],

      ["Clone existing slides and objects", "TRUE", "FALSE", "FALSE"],

      ["Master Slides for academic/corporate branding", "TRUE", "TRUE", "TRUE"],

      [
        "Supports all major object types (charts, shapes, tables etc)",
        "TRUE",
        "TRUE",
        "TRUE",
      ],

      [
        "Create new objects (charts, shapes, tables etc)",
        "TRUE",
        "TRUE",
        "TRUE",
      ],
    ],
  };

  return (
    <>
      <CardFullTable className={styles.comparisonTable} {...cardfulltable} />
    </>
  );
};

export default ComparisonTable;
