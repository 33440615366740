import React from "react";

const HowItWorks = (props) => {
  return (
    <div className="container mx-auto" id="how-works">
      <div className="justify-center text-center flex flex-wrap mt-24">
        <div className="w-full md:w-6/12 px-12 md:px-4">
          <h2 className="font-semibold text-4xl">How it works</h2>
          <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
            A powerful and flexible API that enables you to do almost anything
            with PowerPoint
          </p>
        </div>
      </div>
      <div className="w-full px-4">
        <div className="w-full px-4 flex flex-wrap">
          <div className="flex-1 bg-white rounded-lg p-8 m-4  shadow-lg">
            <div className="px-4 py-5 flex flex-col justify-center items-center">
              <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                <i className="far fa-file-powerpoint"></i>
              </div>
              <h6 className="text-xl mb-1 font-semibold text-center mb-4">
                Step 1
              </h6>
              <p className="text-l mt-2 mb-2 text-blueGray-800 text-center">
                Prepare your PowerPoint template
              </p>
              <p className="text-sm mt-2 mb-4 text-blueGray-500 text-center">
                Design your PowerPoint template directly in PowerPoint. Stay on
                brand and stay in control of how your final presentation will
                look.
              </p>
            </div>
          </div>
          <div className="flex-1 bg-white rounded-lg p-8 m-4 shadow-lg">
            <div className="px-4 py-5 flex flex-col justify-center items-center">
              <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                <i className="fas fa-server"></i>
              </div>
              <h6 className="text-xl mb-1 font-semibold text-center mb-4">
                Step 2
              </h6>
              <p className="text-l mt-2 mb-2 text-blueGray-800 text-center">
                Send an API request
              </p>
              <p className="text-sm mt-2 mb-4 text-blueGray-500 text-center">
                Post a JSON payload with your data.
              </p>
            </div>
          </div>
          <div className="flex-1 bg-white rounded-lg p-8 m-4  shadow-lg">
            <div className="px-4 py-5 flex flex-col justify-center items-center">
              <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                <i className="fas fa-download"></i>
              </div>
              <h6 className="text-xl mb-1 font-semibold text-center mb-4">
                Step 3
              </h6>
              <p className="text-l mt-2 mb-2 text-blueGray-800 text-center">
                Get your populated PowerPoint document
              </p>
              <p className="text-sm mt-2 mb-4 text-blueGray-500 text-center">
                All templated objects, from charts to text boxes, will be
                updated using your data.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
