import React from 'react'
import './Popup.css'
  
function Popup(props) {
    return (props.trigger) ? (
            <div className="popup">
                <div className="flex-1 bg-white rounded-lg p-8 m-4 popUpContent" >
                <i onClick={() => props.setTrigger(false)} className="fas fa-window-close icon-arrow-right"></i>
                    <h2 style={{color:'red'}} className="text-center text-2xl font-semibold leading-tight mt-0 mb-2 text">WAIT! BEFORE YOU GO!</h2>
                    <h1 className="text-center font-semibold text-4xl text">Get PowerPoint automation for even less</h1>

                    <p className="mt-4 text-lg leading-relaxed text-blueGray-500 text-center text">
                        In addition to a 30 day free trial, get <b>25% off</b> for 3 months with coupon code:
                    </p>

                    <p className="text-center text-2xl leading-tight mt-0 mb-2 text2">
                        <div style={{color:'red'}}>Coupon: <b>AutomationNow</b></div>
                    </p>

                        <div className="text-center">
                            <button className="fullWidth get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-600 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150" onClick={() => props.setTrigger(false)}>
                                Yes, give me automated PowerPoint for even less
                            </button>
                            <div className="divider"></div>
                            <button className="inline-block outline-none focus:outline-none align-middle transition-all duration-150 ease-in-out uppercase border border-solid font-bold last:mr-0 mr-2  text-blueGray-800 bg-blueGray-200 border-blueGray-200 active:bg-blueGray-300 active:border-blueGray-300 text-sm px-6 py-2 shadow hover:shadow-lg rounded-md" onClick={() => props.setTrigger(false)}>
                                No, I want to keep doing things manualy
                            </button>
                        </div>
                    </div>
                { props.children }
            </div>
    ) : "";
}

export default Popup