import React from "react";
import ZohoPricing from "components/pricing/ZohoPricing";
import IndexNavbar from "components/Navbars/Navbar.js";
import Footer from "components/Footers/Footer.js";

function Pricing() {
  return (    
    <>
      <IndexNavbar fixed /> 
      <ZohoPricing />  
      <Footer />
    </>
  );
}

export default Pricing;
