import React from "react";

const colors = {
  blueGray: "bg-blueGray-500",
  red: "bg-red-500",
  orange: "bg-orange-500",
  amber: "bg-amber-500",
  emerald: "bg-emerald-500",
  teal: "bg-teal-500",
  lightBlue: "bg-lightBlue-500",
  indigo: "bg-indigo-500",
  purple: "bg-purple-500",
  pink: "bg-pink-500",
  "blueGray-gradient":
    "bg-gradient-to-tl from-blueGray-700 to-blueGray-500 bg-blueGray-500",
  "red-gradient": "bg-gradient-to-tl from-red-700 to-red-500 bg-red-500",
  "orange-gradient":
    "bg-gradient-to-tl from-orange-700 to-orange-500 bg-orange-500",
  "amber-gradient":
    "bg-gradient-to-tl from-amber-700 to-amber-500 bg-amber-500",
  "emerald-gradient":
    "bg-gradient-to-tl from-emerald-700 to-emerald-500 bg-emerald-500",
  "teal-gradient": "bg-gradient-to-tl from-teal-700 to-teal-500 bg-teal-500",
  "lightBlue-gradient":
    "bg-gradient-to-tl from-lightBlue-700 to-lightBlue-500 bg-lightBlue-500",
  "indigo-gradient":
    "bg-gradient-to-tl from-indigo-700 to-indigo-500 bg-indigo-500",
  "purple-gradient":
    "bg-gradient-to-tl from-purple-700 to-purple-500 bg-purple-500",
  "pink-gradient": "bg-gradient-to-tl from-pink-700 to-pink-500 bg-pink-500",
};

// const listColors = {
//   blueGray: "text-blueGray-500",
//   red: "text-red-500",
//   orange: "text-orange-500",
//   amber: "text-amber-500",
//   emerald: "text-emerald-500",
//   teal: "text-teal-500",
//   lightBlue: "text-lightBlue-500",
//   indigo: "text-indigo-500",
//   purple: "text-purple-500",
//   pink: "text-pink-500",
// };
const CardPricingDetails = (props) => {
  const {
    head,
    color,
    description,
    title,
    list,
    redirectUrl,
    buttonText,
    subMessage,
    showButton,
    gradient,
    onSubscribe,
    planCode,
  } = props;
  return (
    <div
      className={
        colors[color + (gradient ? "-gradient" : "")] +
        " mb-6 text-center text-white shadow-lg rounded-lg relative flex flex-col min-w-0 break-words w-full h-full mb-6 text-white"
      }
    >
      <div className="bg-transparent first:rounded-lg-t px-5 py-3 mx-4 border-b border-black border-opacity-10">
              <h4 className="font-bold uppercase my-2 text-white">{head.includes("Free") || head.includes("free") ?"Free":head}</h4>
      </div>
      <div className="lg:px-12 px-4 py-5 flex-auto">
        <div className="text-6xl font-normal leading-tight font-bold text-white">
          {title}
        </div>
        <span>{description}</span>
        <ul className="my-8 list-none">
          {list.map(function (prop, key) {
            return (
              <li key={key} className="py-2">
                <div className="flex justify-center items-center">
                  <h6 className="text-lg">{prop.text}</h6>
                </div>
              </li>
            );
          })}
        </ul>
        <div className="mt-2 px-4 py-4 border-t border-black border-opacity-10 mx-4">
          {showButton ? (
            <button
              style={{
                width: "fit-content",
                borderRadius: "5px",
                backgroundColor: "green",
                color: "white",
                padding: "5px",
                paddingLeft: "15px",
                paddingRight: "15px",
                fontWeight: "bold",
                fontSize: "14px",
                justifySelf: "center",
                alignSelf: "center",
              }}
              onClick={(e) => onSubscribe(e, redirectUrl, planCode)}
            >
              {buttonText}
            </button>
          ) : (
            <></>
          )}
        </div>
        <span>{subMessage}</span>
      </div>
    </div>
  );
};

export default CardPricingDetails;
