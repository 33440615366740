import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import "./custom.scss";

import React, { useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { BsChevronUp, BsChevronDown } from "react-icons/bs";

const AdminSidebar = (props) => {
  //docs here: https://www.npmjs.com/package/react-pro-sidebar

  const [selected, setSelected] = useState(0);
  const [openSignOut, setOpenSignOut] = useState(false);
  const location = useLocation();
  const wrapperRef = useRef(null);
  const signOut = () => {
    localStorage.removeItem("Name");
    localStorage.removeItem("Access_Token");
    window.location.href = "/";
  };
  const getLocalStorageWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  };

  const baseRoutes = [
    { title: "Home", link: "/home" },
        { title: "Getting started", link: "/gettingstarted" },
        { title: "Api Key", link: "/apikey" },
        { title: "Manage bearer token", link: "/managebearertoken" },
        { title: "Example API calls", link: "/exampleapi" },
        {
          title: (
            <a
              href="https://docs.powerpointgeneratorapi.com/"
              rel="noreferrer noopener"
              target="_blank"
            >
              Documentation <i className="fa-solid fa-arrow-up-right-from-square"/>
            </a>
          ),
          link: null,
        },
        { title: "Manage subscription", link: "/managesubscription" },
        {
          title: (
            <a
              href="https://github.com/Indico-Labs/PowerPoint-Generator-API-Community/discussions"
              rel="noreferrer noopener"
              target="_blank"
            >
              Forum <i className="fa-solid fa-arrow-up-right-from-square"/>
            </a>
          ),
          link: null,
        }       
  ]

  const routes = getLocalStorageWithExpiry("IsAdmin")
    ? [
        ...baseRoutes,
        { title: "Dashboard", link: "/usage" },
      ]
    : [
        ...baseRoutes
      ];

  const makeRoutes = routes.map((route, index) => {
    return (
      <MenuItem
        style={{
          fontWeight: selected === index ? "bold" : "normal",
          textDecoration: selected === index ? "underline" : "",
        }}
        key={index}
        active={selected === index}
      >
        {route.title} {route.link ? <Link to={route.link} /> : ""}
      </MenuItem>
    );
  });

  useEffect(() => {
    routes.forEach((route, index) => {
      if (route.link === location.pathname) {
        setSelected(index);
      }
    });
    //eslint-disable-next-line
  }, [location.pathname]);

  const useDetectClickOutside = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenSignOut(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useDetectClickOutside(wrapperRef);

  return (
    <div style={{ height: "100vh", display: "flex",height: "100vh",
                  display: "flex",
                  borderRight: "1px solid #d3d3d3",
                  zIndex: "1",
                  boxShadow: "1px 5px 5px #6e6e6e" }}>
      <ProSidebar>
        <SidebarHeader>
          <h1
            style={{ fontWeight: "bold", padding: "5px", paddingLeft: "20px" }}
          >
            
            <Link to="/">POWERPOINT GENERATOR API</Link>
          </h1>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square">
            {makeRoutes}
            <MenuItem ref={wrapperRef} className="signout-link" onClick={()=>{setOpenSignOut(!openSignOut)}}>{localStorage.getItem('Name')&& JSON.parse(localStorage.getItem('Name')).value} 
            {openSignOut?<BsChevronDown className="signout-down-icon"/>:<BsChevronUp className="signout-up-icon"/>} 
            {openSignOut && <div className="signout-container"><div onClick={signOut} className="link-list">Sign out</div></div>}</MenuItem>
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </div>
  );
};

export default AdminSidebar;
