import React, { Component } from "react";
import { hotjar } from "react-hotjar";
import { Link } from "react-router-dom";

class Login extends Component {
  state = {
    email: "",
    password: "",
    emailValid: true,
    requestGotError: false,
    requestErrorMessage: "",
    buttonText: "Sign in"
  };

  componentDidMount() {
    hotjar.initialize("2739117", 6);
  }
  changeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  setLocalStorageWithExpiry(key, value, ttl) {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }
  login = (e) => {
    e.preventDefault();

    const { email, password } = this.state;

    // if(!this.validateForm(this.state))
    //     return;

    var postData = new URLSearchParams();
    postData.append("userName", email);
    postData.append("password", password);
    // POST request with a JSON body using fetch
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      body: postData.toString(),
    };
    this.setState({ buttonText: "Loading" });
    fetch(
      `${process.env.REACT_APP_PGA_USER_API_URL}/Token/SiteLogin`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        //debugger;
        if (!data.message) {
          this.setState({
            email: "",
            password: "",
            emailValid: true,
            requestGotError: false,
            requestErrorMessage: "",
          });
          this.setLocalStorageWithExpiry(
            "Name",
            data.result.username,
            1000 * 60 * 30
          ); //30 mins
          this.setLocalStorageWithExpiry(
            "Access_Token",
            data.result.access_Token,
            1000 * 60 * 30
          );
          this.setLocalStorageWithExpiry(
            "IsAdmin",
            data.result.isAdmin,
            1000 * 60 * 30
          );
          if (data.result.hasSubscription === false) {
            window.location.href = "/SelectSubscription";
          } else {
            window.location.href = "/home";
          }
        } else {
          localStorage.removeItem("Name");
          localStorage.removeItem("Access_Token");
          this.setState({
            confirmEmail: false,
            requestGotError: true,
            requestErrorMessage: data.message,
            buttonText: "Sign in",
          });
        }
      });
  };

  render() {
    const { email, password, requestErrorMessage, requestGotError, buttonText } =
      this.state;
    return (
      <div className="container mx-auto px-4 h-full vertical-center">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "5px",
                justifyContent: "center",
                paddingBottom: "1rem",
                alignItems: "flex-start",
              }}
            >
              <img
                src="/logo.png"
                style={{
                  width: "22px",
                  height: "31px",
                }}
                alt="The PGA Logo"
              />
              <p
                style={{ paddingLeft: "15px", paddingTop: "4px" }}
                className="font-bold uppercase mb-2"
              >
                PowerPoint Generator API
              </p>
            </div>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>Sign in with credentials</small>
                </div>
                <form onSubmit={this.login}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      name="email"
                      id="email"
                      value={email}
                      onChange={this.changeInput}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      value={password}
                      id="password"
                      name="password"
                      onChange={this.changeInput}
                    />
                  </div>

                  <div
                    className="alert alert-warning"
                    role="alert"
                    style={
                      requestGotError ? { color: "red" } : { display: "none" }
                    }
                  >
                    {requestErrorMessage}
                  </div>

                  <div className="text-center mt-6">
                    <button
                      data-testid="submit"
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      {buttonText}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <Link to="/changePassword" className="text-blueGray-800">
                  <small>Forgot password?</small>
                </Link>
              </div>
              <div className="w-1/2 text-right">
                <Link to="/auth/register" className="text-blueGray-800">
                  <small>Create new account</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
