import React from "react";
import { Link } from "react-router-dom";

const FinalCTA = (props) => {
    return (
        <section className=" pb-40 relative bg-blueGray-800">
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-100 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
          <div className="container mx-auto items-center flex flex-wrap pt-24">
            <div className="w-full md:w-12/12 lg:w-12/12 xl:w-12/12 px-4">
              <div className="pt-32 sm:pt-0 text-center">
                <h2 className="font-semibold text-4xl text-white">
                  Automate PowerPoint Generation
                </h2>
                <p className="mt-4 text-lg leading-relaxed text-white">
                  Create, fill and manage PowerPoint documents through simple
                  API requests.
                </p>
                <div className="mt-12">
                  <Link
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                    to="/tryfree"
                  >
                    Get started free
                  </Link>
                  <div className="mt-6 text-md leading-relaxed text-blueGray-400">
                    <i>No credit card required</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    )
}

export default FinalCTA