import React from "react";
import AdminSidebar from "components/Sidebar/AdminSidebar";
import CardGettingStarted from "components/Cards/CardGettingStarted";
import styles from "./GettingStarted.module.css";
import BlueBackground from "components/Cards/BlueBackground";

const GettingStarted = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "100vh",
          backgroundColor: "rgb(241,245,249)",
        }}
      >
        <AdminSidebar />
        <div className={styles.gettingStarted}>
          <h3
            className={styles.title}
            style={{ color: "white", fontSize: "14px" }}
          >
            Getting started
          </h3>
          <CardGettingStarted />
        </div>
        <BlueBackground />
      </div>
    </>
  );
};

export default GettingStarted;
