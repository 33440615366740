import { useEffect, useState } from "react";
import styles from "./CardBearerToken.module.css";
const CardBearerToken = (props) => {
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  //const [exampleToken, setExampleToken] = useState("");

  const getLocalStorageWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  };

  useEffect(() => {
    setToken(getLocalStorageWithExpiry("Access_Token"));
    setName(getLocalStorageWithExpiry("Name")); //30 mins
    
  }, []);

  useEffect(()=>{
    if (name == null || typeof name === "undefined") {
      window.location.href = "/login";
    }
  },[name])
  const onSubmitForm = (event) => {
    event.preventDefault();

    var postData = new URLSearchParams();
    postData.append("username", name);
    postData.append("validHour", props.tokenValidDayCount);
    postData.append("key", props.loginKey);
    // POST request with a JSON body using fetch
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      body: postData.toString(),
    };
    requestOptions.headers["Authorization"] = `Bearer ${token}`;

    fetch(
      `${process.env.REACT_APP_PGA_USER_API_URL}/Token/CreateExampleToken`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.isSucces === true) {
          props.setExampleToken(data.result.access_Token);
        } else {
          props.setExampleToken("");
        }
      });
  };

  const onValChange = (event) => {
    props.setTokenValidDayCount(event.target.value);
  };
  const onKeyValChange = (event) => {
    props.setKeyValue(event.target.value);
  };
  return (
    <div
      class="rounded shadow-lg"
      style={{
        flexDirection: "column",
        flexWrap: "wrap",
        width: "100%",
        paddingInline: "10px",
        backgroundColor: "white",
      }}
    >
      <h1
        style={{
          opacity: "50%",
          fontSize: 12,
          fontWeight: "bold",
        }}
      >
        GENERATE BEARER TOKEN MANUALLY
      </h1>
      <h1
        style={{
          fontWeight: "bold",
          fontSize: 18,
          paddingTop: "5px",
        }}
      >
        To authenticate your calls with the API use a bearer token
      </h1>
      <p2
        style={{
          paddingTop: "20px",
          opacity: "50%",
          fontWeight: "bold",
          fontSize: 14,
        }}
      >
        Token valid for:
      </p2>
      <form
        onSubmit={onSubmitForm}
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "10px",
          justifyContent: "space-between",
          alignItems: "space-between",
        }}
      >
        <label
          style={{
            paddingTop: "5px",
          }}
        >
          <input
            type="radio"
            value="1"
            checked={props.tokenValidDayCount === "1"}
            onChange={onValChange}
          />
          <span> 24 Hours</span>
        </label>

        <label>
          <input
            type="radio"
            value="60"
            checked={props.tokenValidDayCount === "60"}
            onChange={onValChange}
          />
          <span> 60 Days</span>
        </label>

        <label>
          <input
            type="radio"
            value="365"
            checked={props.tokenValidDayCount === "365"}
            onChange={onValChange}
          />
          <span> 365 Days</span>
        </label>
        <span>Security key was sent to your email</span>
        <input
          type="input"
          className="border border-black px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          value={props.loginKey}
          onChange={onKeyValChange}
          defaultValue=""
          placeholder="Key"
        />

        <button className={styles.button} type="submit">
          GET BEARER TOKEN
        </button>
      </form>
    </div>
  );
};

export default CardBearerToken;
