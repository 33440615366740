import React, { useEffect, useState } from "react";
import Pricing4 from "components/pricing/Pricing4";
import styles from "./PGALandingPricing.module.css";
const PGALandingPricing = (props) => {
  const [subscriptionProps, setSubscriptionProps] = useState({
    cards: [],
  });

  useEffect(() => {
    var postData = new URLSearchParams();
    // POST request with a JSON body using fetch
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      body: postData.toString(),
    };

    fetch(
      `${process.env.REACT_APP_PGA_USER_API_URL}/User/GetSubscriptionsForLandingPage`,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Response not OK", response);
      })
      .then((data) => {
        if (data.isSucces === true) {
          setSubscriptionProps({
            cards: [],
          });
          var cards = [];
          var descriptions = [];
          data.result.subscriptions.forEach(function (subscription) {
            descriptions = [];
            subscription.descriptions.forEach(function (desc) {
              descriptions.push({
                text: desc.description,
                color: "lightBlue",
              });
            });
            cards.push({
              head: subscription.name?.toString(),
              title: "£" + subscription.price?.toString(),
              description: "per month",
              list: descriptions,
              color: subscription.color,
              gradient: false,
              redirectUrl: subscription.redirectUrl,
              showButton: props?.hideSubscribe
                ? false
                : subscription.showButton,
              buttonText: subscription.buttonText?.toString(),
            });
          });
          setSubscriptionProps({
            cards: cards,
          });
        }
      });
  }, [props?.hideSubscribe]);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "fit-content",
        }}
      >
        <div className={styles.manageSub}>
          <Pricing4 className={styles.pricing} {...subscriptionProps} />
        </div>
      </div>
    </>
  );
};
export default PGALandingPricing;
