import React, { useEffect, useState } from "react";
import styles from "./BearerToken.module.css";
import CardBearerToken from "components/Cards/CardBearerToken";
import CardBearerTokenDisplay from "components/Cards/CardBearerTokenDisplay";
import CardDocs from "components/Cards/CardDocs";
import CardUsingDoc from "components/Cards/CardUsingDoc";
import AdminSidebar from "components/Sidebar/AdminSidebar";
import BlueBackground from "components/Cards/BlueBackground";
const BearerToken = (props) => {
  const [tokenValidDayCount, setTokenValidDayCount] = useState("1");
  const [loginKey, setKeyValue] = useState("");
  const [name, setName] = useState("");
  const [exampleToken, setExampleToken] = useState("");
  const [expiryDate, setExpiryDate] = useState("");

  const getLocalStorageWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  };

  useEffect(() => {
    setName(getLocalStorageWithExpiry("Name")); //30 mins
    if (name === null || name === undefined) {
      window.location.href = "/login";
    } else {
      var postData = new URLSearchParams();
      postData.append("username", getLocalStorageWithExpiry("Name"));
      // POST request with a JSON body using fetch
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
        body: postData.toString(),
      };
      requestOptions.headers[
        "Authorization"
      ] = `Bearer ${getLocalStorageWithExpiry("Access_Token")}`;

      fetch(
        `${process.env.REACT_APP_PGA_USER_API_URL}/User/CheckSubscription`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          //debugger;
          if (data.isSucces === false) {
            window.location.href = "/SelectSubscription";
          }
        });
    }
  }, [name]);

  useEffect(() => {
    const date = new Date();
    const exDate = new Date();
    exDate.setDate(date.getDate() + parseInt(tokenValidDayCount));
    setExpiryDate(exDate.toDateString());
  }, [exampleToken, tokenValidDayCount]);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "100vh",
          backgroundColor: "rgb(241,245,249)",
        }}
      >
        <AdminSidebar />
        <div className={styles.adminArea}>
          <h3
            className={styles.title}
            style={{ color: "white", fontSize: "14px" }}
          >
            Manage bearer token
          </h3>
          <CardBearerToken
            tokenValidDayCount={tokenValidDayCount}
            setTokenValidDayCount={setTokenValidDayCount}
            loginKey={loginKey}
            setKeyValue={setKeyValue}
            setExampleToken={setExampleToken}
          />
          <CardBearerTokenDisplay
            expiryDate={expiryDate}
            exampleToken={exampleToken}
          />
          <CardDocs />
          <CardUsingDoc />
        </div>
        <BlueBackground />
      </div>
    </>
  );
};
export default BearerToken;
