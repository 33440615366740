/*eslint-disable*/
import React from "react";

import IndexNavbar from "components/Navbars/Navbar.js";
import Footer from "components/Footers/Footer.js";
import InfoAreaIconTitleList from "@notus-pro/react/InfoAreaIconTitleList"

import exampleJson from '../assets/json/example.json'

const props = {
  icon: "fas fa-toolbox",
  color: "lightBlue",
  title: "The power of PowerPoint Generator API",
  description:"",
  list: [
    { icon: "fas fa-check", text: "Automate PowerPoint document creation" },
    { icon: "fas fa-check", text: "Template driven, reusable and customisable" },
    { icon: "fas fa-check", text: "Design your templates in Powerpoint and stay on brand" },
    { icon: "fas fa-check", text: "Fill any PowerPoint objects from  charts to text boxes with your data" },
    { icon: "fas fa-check", text: "Automate updating dates, project and client names across all slides with tagging" },
    { icon: "fas fa-check", text: "Continue working on completely editable and native PowerPoint presentations" },
    { icon: "fas fa-check", text: "Available at the click of a button, instantly and at a low cost" }
  ],
}

export default function CookiePolicy() {
  return (
    <>
      <IndexNavbar fixed />

      <section className="bg-white overflow-hidden" >
        <div className="container mx-auto pb-32  pt-12">
          <div className="container mx-auto px-4 pb-6" id="cookies">
            <div className="justify-center text-left flex flex-wrap mt-24">
              <div className="w-full md:w-12/12 px-12 md:px-4">
                <h2 className="font-semibold text-4xl text-blueGray-600">Cookies</h2>
                <div class="mb-8 text-base text-blueGray-500">
                <p>
                <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500"><b>Why we use cookies</b></p>
                <p class="mb-4 text-base text-blueGray-500">  
                  We use cookies on our website to understand more about our users and to improve their experience across our website.  The purposes for which they are used, are set out below:
                </p>
                <ul class="mb-4 text-base text-blueGray-500 list-none">
                  <li class="py-1">
                    - improve the website's usability
                  </li>
                  <li class="py-1">
                    - analyse the use of the website
                  </li>
                  <li class="py-1">
                    - administer the website
                  </li>
                  <li class="py-1">
                   - prevent fraud
                  </li>
                  <li class="py-1">
                    - improve the security of the website
                  </li>
                  <li class="py-1">
                    - personalise the website for our users.
                  </li>
                </ul>
              
              <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500"><b>What are cookies</b></p>
              <p class="mb-4 text-base text-blueGray-500">
                A cookie is a file containing an identifier a string of letters and numbers that is sent by a web server to a web browser and is stored by the browser. The identifier is then sent back to the server each time the browser requests a page from the server.
              </p>
              <p class="mb-4 text-base text-blueGray-500">
                Cookies may be either "persistent" cookies or "session" cookies: a persistent cookie will be stored by a web browser and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end of the user session, when the web browser is closed.  Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies.
              </p>
              <p class="mb-4 text-base text-blueGray-500">
              Cookies can be used by web servers to identify and track users as they navigate different pages on a website and identify users returning to a website.
              </p>

              <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500"><b>Analytics cookies</b></p>
              
              <p class="mb-4 text-base text-blueGray-500">
                We use Google Analytics Universal to analyse the use of our website.  Our analytics service provider generates statistical and other information about website use by means of cookies.
              </p>
              <p class="mb-4 text-base text-blueGray-500">
                The information generated relating to our website is used to create reports about the use of our website.  Our analytics service provider's privacy policy is available at: <a href="http://www.google.com/policies/privacy"><u>http://www.google.com/policies/privacy.</u></a>
              </p>
              <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                <b>
                  Managing your cookies settings
                </b>
              </p>
              <p class="mb-4 text-base text-blueGray-500">
                Most browsers allow you to change your settings and preferences for cookies.  Use the links below to take a look at individual browser settings:
              </p>
              <ul class="mb-4 text-base text-blueGray-500">
                <li class="py-1">
                  <a href="http://windows.microsoft.com/en-gb/windows7/how-to-manage-cookies-in-internet-explorer-9"> - <u>Internet explorer - our website only supports IE9 or above</u></a>
                </li>
                <li class="py-1">
                <a href="https://support.google.com/chrome/answer/95647?hl=en-GB">- <u>Chrome</u></a>
                </li>
                <li class="py-1">
                <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">- <u>Firefox</u></a>
                </li>
                <li class="py-1">
                <a href="https://support.apple.com/kb/PH21411?viewlocale=en_US&amp;locale=en_US">- <u>Safari</u></a>
                </li>
                <li class="py-1">
                <a href="https://support.apple.com/en-gb/HT201265">- <u>Safari (IOS)</u></a>
                </li>
                <li class="py-1">
                <a href="http://uk.blackberry.com/legal/cookies.html">- <u>Blackberry</u></a>
                </li>
                <li class="py-1">
                <a href="http://www.windowsphone.com/en-gb/how-to/wp7/web/changing-privacy-and-other-browser-settings"> - <u>Windows phone</u></a>
                </li>
              </ul>
              <p class="mb-4 text-base text-blueGray-500">
                If you are using a browser we have not included above please refer to their website and support services.</p>
              </p>
          </div>
          </div>     
        </div>
      </div>
    </div>
  </section>
     
      <Footer />
    </>
  );
}
