import React from "react";

const Objections = (props) => {
  const objections = [
    {
      icon: "fas fa-ice-cream",
      title: "Easy Integration",
      description:
        "Use our integration guide or talk to one of our experts about our integration service.",
    },
    {
      icon: "fas fa-chart-bar",
      title: "Get Started For Free",
      description:
        "Only pay for what you need and cancel your subscription at any time.",
    },
    {
      icon: "fas fa-wand-magic-sparkles",
      title: "Completely Custom Download",
      description:
        "Design your export in PowerPoint and populate your charts, text boxes and tables using our smart API.",
    },
  ];

  const renderObjections = objections.map((objection) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "auto",
            maxWidth: "20%",
          }}
        >
          <i
            style={{
              backgroundColor: "white",
              borderRadius: "25px",
              padding: "10px",
            }}
            class={objection.icon}
          />
          <h2 className="text-base font-semibold text-center text-white">
            {objection.title}
          </h2>
          <p className="text-xs text-center text-blueGray-200">
            {objection.description}
          </p>
        </div>
      </>
    );
  });

  return (
    <>
      <section
        style={{
          transform: "skewY(-5deg)",
          backgroundColor: "rgb(31 41 55)",
          padding: "100px",
          paddingTop: "200px",
          position: "relative",
          marginTop: "100px",
        }}
        id="product"
      >
        <div
          style={{
            transform: "skewY(5deg)",
            margin: "0 auto",
          }}
        >
          <div className="flex items-center justify-center" id="documentation">
            {renderObjections}
          </div>
          <div className="w-full md:w-6/12 sm:w-8/12 mt-20  mr-auto ml-auto ">
            <div className="w-full flex flex-row justify-center items-center">
              <div className="mt-6">
                <button
                  onClick={props.togglePopup}
                  target="_blank"
                  className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Objections;
