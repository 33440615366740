import React from "react";

const BlueBackground = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        backgroundColor: "#32bcf3",
        width: "100%",
        height: "300px",
        zIndex: "0",
      }}
    />
  );
};

export default BlueBackground;
