import React from "react";

const CardHeaderStat = (props) => {
    console.log(props)
    if (props.trafficCardProps.statTitle.includes("Free") || props.trafficCardProps.statTitle.includes("free")) {
        props.trafficCardProps.statTitle = "Free"
    }
  return (
    <div
      class="shadow-lg"
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        paddingInline: "10px",
        gridArea: props.gridArea,
        width: "300px",
        height: "120px",
        paddingBottom: "15px",
        borderRadius: "0.25rem",
        overflow: "hidden",
        backgroundColor: "white",
      }}
    >
      <h1 style={{
         opacity: "50%",
         fontSize: "smaller",
         fontWeight: "bold",
         paddingTop: "10px",
      }}>
        {props.trafficCardProps.statHeader
          ? props.trafficCardProps.statHeader
          : ""}
      </h1>
      <h1
        style={{
          fontWeight: "bold",
          fontSize: "18px",
        }}
          >              
        {props.trafficCardProps.statTitle
          ? props.trafficCardProps.statTitle
          : ""}
      </h1>
      <h1
        style={{
          paddingTop: "15px",
          fontWeight: "bold",
          fontSize: "12px",
          opacity: "70%",
        }}
      >
        {props.trafficCardProps.statSubtitle
          ? props.trafficCardProps.statSubtitle
          : ""}
      </h1>
    </div>
  );
};

export default CardHeaderStat;
