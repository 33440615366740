import React, { useState, useEffect } from "react";
import AdminSidebar from "components/Sidebar/AdminSidebar";
import CardApiKey from "components/Cards/CardApiKey";
import styles from "./ManageSubscription.module.css";
import BlueBackground from "components/Cards/BlueBackground";

const ApiKey = (props) => {
 
  const [userApiKey, setUserApiKey] = useState("");

  const getLocalStorageWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  };

  useEffect(() => {
    
    // POST request with a JSON body using fetch
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
    };
    requestOptions.headers[
      "Authorization"
    ] = `Bearer ${getLocalStorageWithExpiry("Access_Token")}`;

    fetch(
      `${process.env.REACT_APP_PGA_USER_API_URL}/user/keys`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setUserApiKey(data?.result?.keys)
      });
      
  }, []);
  
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "100vh",
          backgroundColor: "rgb(241,245,249)",
        }}
      >
        <AdminSidebar />

        <div className={styles.manageSub}>
          <h3
            className={styles.title}
            style={{ color: "white", fontSize: "14px" }}
          >
            Api Key
          </h3>
          <div>
            <CardApiKey
              gridArea="lastMonth"
              userApiKey={userApiKey}
            />
          </div>
        </div>
        <BlueBackground />
      </div>
    </>
  );
};

export default ApiKey;
