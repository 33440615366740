import React from "react";
import PGALandingPricing from "components/pricing/PGALandingPricing";

const Pricing = (props) => {
  return (
    <section className="bg-white overflow-hidden h-max">
      <div className="container mx-auto pb-32 pt-12 h-max">
        <div className="container mx-auto px-4 pb-6" id="pricing">
          <div className="justify-center text-center flex flex-wrap mt-24">
            <div className="w-full md:w-6/12 px-12 md:px-4">
              <h2 className="font-semibold text-4xl text-blueGray-600">
                Pricing
              </h2>
            </div>
          </div>
        </div>

        <PGALandingPricing />
      </div>
    </section>
  );
};

export default Pricing;
