
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const CardGettingStarted = (props) => {
  const [name, setName] = useState("");

  const getLocalStorageWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  };

  useEffect(() => {
    setName(getLocalStorageWithExpiry("Name")); //30 mins
  }, []);

  useEffect(()=> {
    if (name == null || typeof name === "undefined") {
      window.location.href = "/login";
    }
  }, [name])

  return (
    <div
      class="rounded shadow-lg"
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        width: "100%",
        height: "fit-content",
        paddingInline: "10px",
        paddingBottom: "20px",
        backgroundColor: "white",
      }}
    >
      <h1
        style={{
          opacity: "50%",
          fontSize: 12,
          fontWeight: "bold",
        }}
      >
        GETTING STARTED
      </h1>
      <h1
        style={{
          fontWeight: "bold",
          fontSize: 18,
          paddingTop: "5px",
        }}
      >
        Welcome to PowerPoint Generator API. Let's get you started
      </h1>
      <p2
        style={{
          paddingTop: "20px",
          opacity: "50%",
          fontWeight: "bold",
          fontSize: 14,
        }}
      >
        Step 1: Generate a bearer token
      </p2>
      <p2>
        To secure your data, all calls to the API must be authenticated with a
        bearer token.
      </p2>
      <Link to="/managebearertoken" style={{ color: "blue", textDecoration: "underline" }}>
        Create a bearer token
      </Link>

      <p2
        style={{
          paddingTop: "20px",
          opacity: "50%",
          fontWeight: "bold",
          fontSize: 14,
        }}
      >
        Step 2: Generate your first PowerPoint
      </p2>
      <p2>We've provided you with some examples to get started.</p2>
      <Link style={{ color: "blue", textDecoration: "underline" }} to="/exampleapi">
        See our examples
      </Link>

      <p2
        style={{
          paddingTop: "20px",
          opacity: "50%",
          fontWeight: "bold",
          fontSize: 14,
        }}
      >
        Step 3: Get familiar with the documentation
      </p2>
      <a
        style={{ color: "blue", textDecoration: "underline" }}
        href="https://docs.powerpointgeneratorapi.com/"
        rel="noreferrer noopener"
        target="_blank"
      >
        See our docs
      </a>

      <p2
        style={{
          paddingTop: "20px",
          opacity: "50%",
          fontWeight: "bold",
          fontSize: 14,
        }}
      >
        Step 4: Choose your subscription plan
      </p2>
      <p2>
        PowerPoint Generator is{" "}
        <span style={{ fontWeight: "bold" }}>free forever</span> with our free
        plan. Choose the plan that works best for you.
      </p2>
      <Link
        style={{ color: "blue", textDecoration: "underline" }}
        to="/managesubscription"
      >
        Choose the best plan for you
      </Link>
    </div>
  );
};

export default CardGettingStarted;
