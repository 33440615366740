import CardBlogImage from "components/Cards/CardBlogImage";
import React from "react";
import image from "assets/img/pga-quote.jpg"

const TimeObjection = (props) => {
  const cardProps = {
    image:
      image,
    title: "",
    description: `"We build custom Tableau dashboards for our clients. We didn't realise how much of an impact adding a customized PowerPoint download would have. It's all our customers talk about!"`,
    color: "lightBlue",
    quotename: "Aaron Moore",
  };

  return (
    <section
      style={{
        transform: "skewY(-5deg)",
        backgroundColor: "rgb(209 213 219)",
        padding: "100px",
        position: "relative",
        width: "100vw",
      }}
      id="product"
    >
      <div
        style={{
          transform: "skewY(5deg)",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            maxWidth: "40%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          className="container mx-auto px-4 pt-24 pb-4 "
          id="documentation"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="justify-center text-center flex-col flex-wrap  mx-24"
          >
            <h2 className="text-2xl font-bold text-left w-2/12 text-black">
              Don't have the time to complete the integration yourself?
            </h2>
            <p className="text-base text-left w-2/12 text-blueGray-500">
              Outsource the development work to us. We offer a full integration
              service, starting from £800/$500 per dashboard.
            </p>
          </div>
        </div>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            width: "50%",
          }}
        >
          <CardBlogImage {...cardProps} />
        </div>
      </div>
    </section>
  );
};

export default TimeObjection;
