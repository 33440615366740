import React, { useEffect, useState } from "react";
import Pricing4 from "components/pricing/Pricing4";
import styles from "./ManageSubscription.module.css";

const SelectSubscription = (props) => {
  const [subscriptionProps, setSubscriptionProps] = useState({
    cards: [],
  });
  const [name, setName] = useState("");
  const [token, setToken] = useState("");
  const [plan, setPlan] = useState("");
  const getLocalStorageWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  };

  useEffect(() => {
    setToken(getLocalStorageWithExpiry("Access_Token"));
    setName(getLocalStorageWithExpiry("Name")); //30 mins

    if (name === null || name === undefined) {
      window.location.href = "/login";
    }
  }, [name]);

  useEffect(() => {
    var postData = new URLSearchParams();
    postData.append("username", getLocalStorageWithExpiry("Name"));
    // POST request with a JSON body using fetch
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      body: postData.toString(),
    };

    requestOptions.headers[
      "Authorization"
    ] = `Bearer ${getLocalStorageWithExpiry("Access_Token")}`;

    fetch(
      `${process.env.REACT_APP_PGA_USER_API_URL}/User/GetUserManagementDashboard`,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Response not OK", response);
      })
      .then((data) => {

        if (data.isSucces === true) {
          setSubscriptionProps({
            cards: [],
          });
          var cards = [];
          var descriptions = [];
          data.result.subscriptions.forEach(function (subscription) {
            descriptions = [];
            subscription.descriptions.forEach(function (desc) {
              descriptions.push({
                text: desc.description,
                color: "lightBlue",
              });
            });
            setPlan(data.result.plan)
            cards.push({
              head: subscription.name?.toString(),
              title: "£" + subscription.price?.toString(),
              description: "per month",
              list: descriptions,
              color: subscription.color,
              gradient: false,
              redirectUrl: subscription.redirectUrl,
              showButton: subscription.showButton,
              buttonText: subscription.buttonText?.toString(),
              subMessage: subscription.subMessage?.toString(),
              planCode: subscription.planCode?.toString(),
            });
          });
          setSubscriptionProps({
            cards: cards,
          });
        }
      });
  }, [name]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "100vh",
        }}
      >       
        <div className={styles.manageSub}>
          <h3
            className="text-center"
            style={{ color: "black", fontSize: "24px" }}
          >
            Choose account type that works for you
          </h3>
          <span  className="text-center" >You can change this later</span>
          <div className={styles.infoCard}>            
          </div>
          <Pricing4 {...subscriptionProps} name={name} token={token} plan={plan} />
          <br />
        </div>
        <div
          style={{
            position: "absolute",
            backgroundColor: "#32bcf3",
            width: "100%",
            height: "300px",
            zIndex: "-1",
          }}
        ></div>
      </div>
    </>
  );
};

export default SelectSubscription;
