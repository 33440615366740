const CardDocs = (props) => {
  return (
    <div
      class="rounded overflow-hidden shadow-lg"
      style={{
        flexDirection: "column",
        flexWrap: "wrap",
        paddingInline: "10px",
        gridArea: "genDoc",
        backgroundColor: "white",
      }}
    >
      <h1
        style={{
          opacity: "50%",
          fontSize: 12,
          fontWeight: "bold",
          paddingTop: "10px",
        }}
      >
        GENERATE BEARER TOKEN PROGRAMATICALLY
      </h1>
      <h1
        style={{
          fontWeight: "bold",
          fontSize: 18,
          paddingTop: "5px",
        }}
      >
        How to generate your bearer token with an API call
      </h1>
      <div
        style={{
          paddingLeft: "5px",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
        }}
      >
        <p2
          style={{
            opacity: "50%",
            fontWeight: "normal",
            fontSize: 14,
            paddingTop: "20px",
          }}
        >
          <span
            style={{
              backgroundColor: "darkGreen",
              color: "white",
              borderRadius: "10px",
              padding: "5px",
              fontWeight: "bold",
            }}
          >
            POST
          </span>
          <span style={{ paddingLeft: "20px" }}>
            {process.env.REACT_APP_PGA_USER_API_URL}/
            <span style={{ fontWeight: "bold" }}>token/create</span>
          </span>
        </p2>
        <p2
          style={{
            paddingTop: "15px",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          token/create
        </p2>
        <p2
          style={{
            paddingTop: "15px",
            fontSize: "14px",
            opacity: "75%",
          }}
        >
          This endpoint enables you to create an authentication token. You will
          need this for all requests to the API.
        </p2>
        <p2
          style={{
            paddingTop: "15px",
            fontSize: "16px",
            fontWeight: "bold",
            opacity: "75%",
          }}
        >
          Parameters
        </p2>
        <p2
          style={{
            paddingTop: "15px",
            fontSize: "14px",
            fontWeight: "bold",
            opacity: "75%",
          }}
        >
          Header
        </p2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 4fr",
            gridTemplateRows: "1fr",
            gridTemplateAreas: "header type description",
            padding: "5px",
          }}
        >
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            Content-Type
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            string
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            multipart/form-data
          </p2>
        </div>

        <p2
          style={{
            paddingTop: "15px",
            fontSize: "14px",
            fontWeight: "bold",
            opacity: "75%",
          }}
        >
          Body
        </p2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 4fr",
            gridTemplateRows: "1fr",
            gridTemplateAreas: "header type description",
            padding: "5px",
          }}
        >
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            username
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            string
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            Your account username
          </p2>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 4fr",
            gridTemplateRows: "1fr",
            gridTemplateAreas: "header type description",
            padding: "5px",
          }}
        >
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            password
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            string
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            Your account password
          </p2>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 4fr",
            gridTemplateRows: "1fr",
            gridTemplateAreas: "header type description",
            padding: "5px",
          }}
        >
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            key
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            string
          </p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            Account key that was provided when you created your account
          </p2>
        </div>
        <p2
          style={{
            paddingTop: "15px",
            fontSize: "16px",
            fontWeight: "bold",
            opacity: "75%",
          }}
        >
          Responses
        </p2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 4fr",
            gridTemplateRows: "1fr",
            gridTemplateAreas: "header type description",
            padding: "5px",
          }}
        >
          <div
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
              display: "flex",
            }}
          >
            <div
              style={{
                backgroundColor: "green",
                borderRadius: "50%",
                height: "10px",
                width: "10px",
                alignSelf: "center",
              }}
            ></div>
            <p2
              style={{
                fontSize: "14px",
                opacity: "70%",
                paddingLeft: "10px",
              }}
            >
              200
            </p2>
          </div>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          ></p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            Cake successfully retrieved.
          </p2>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 4fr",
            gridTemplateRows: "1fr",
            gridTemplateAreas: "header type description",
            padding: "5px",
          }}
        >
          <div
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
              display: "flex",
            }}
          >
            <div
              style={{
                backgroundColor: "orange",
                borderRadius: "50%",
                height: "10px",
                width: "10px",
                alignSelf: "center",
              }}
            ></div>
            <p2
              style={{
                fontSize: "14px",
                opacity: "70%",
                paddingLeft: "10px",
              }}
            >
              404
            </p2>
          </div>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          ></p2>
          <p2
            style={{
              paddingTop: "5px",
              fontSize: "14px",
              opacity: "70%",
            }}
          >
            Could not find a cake matching this query.
          </p2>
        </div>
      </div>
    </div>
  );
};

export default CardDocs;
