import React, { useEffect } from "react";
import AdminSidebar from "components/Sidebar/AdminSidebar";
import CardExampleAPI from "components/Cards/CardExampleAPI";
import styles from "./ExampleAPI.module.css";
import CardChartExample from "components/Cards/CardChartExample";
import CardFurtherExamples from "components/Cards/CardFurtherExamples";
import BlueBackground from "components/Cards/BlueBackground";

const ExampleAPI = (props) => {
  const getLocalStorageWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  };
  useEffect(() => {
    if (
      getLocalStorageWithExpiry("Name") == null ||
      typeof getLocalStorageWithExpiry("Name") === "undefined"
    ) {
      window.location.href = "/login";
    }
  });
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "100vh",
          backgroundColor: "rgb(241,245,249)",
        }}
      >
        <AdminSidebar />
        <div className={styles.exampleAPI}>
          <h3
            className={styles.title}
            style={{ color: "white", fontSize: "14px" }}
          >
            Example API calls
          </h3>
          <CardExampleAPI />
          <CardChartExample />
          <CardFurtherExamples />
        </div>
        <BlueBackground />
      </div>
    </>
  );
};

export default ExampleAPI;
