import React from "react";
import ComparisonTable from "./ComparisonTable";

const Comparison = (props) => {
  return (
    <div className="container mx-auto" id="compare">
      <div className="justify-center text-center flex flex-wrap mt-24">
        <div className="w-full md:w-6/12 px-12 md:px-4">
          <h2 className="font-semibold text-4xl">How do we compare</h2>
          <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
            See how we compare to other leading PowerPoint generators.
          </p>
        </div>
      </div>
      <ComparisonTable/>
    </div>
  );
};

export default Comparison;
