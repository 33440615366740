import { React, useState } from "react";
import IndexNavbar from "components/Navbars/Navbar.js";
import Footer from "components/Footers/Footer";
import Objections from "components/Tableau/Objections";
import TimeObjection from "components/Tableau/TimeObjection";
import TableauPricing from "components/Tableau/TableauPricing";
import TabHeader from "components/Tableau/TabHeader";
import TableauQuiz from "components/Tableau/TableauQuiz";
import Popup from "components/Tableau/Popup";

const Tableau = (props) => {

  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const scale = Math.min(
    window.innerWidth / 900,
    window.innerHeight / 750, 1
  )
    
  return (
    <div style={{ overflowX: "hidden" }}>
      <IndexNavbar tableau={true} togglePopup={togglePopup} fixed />
      <TabHeader />
      <Objections togglePopup={togglePopup} />
      <TimeObjection />
      <TableauPricing togglePopup={togglePopup} />
      <Footer hideLinks={true} />
      {isOpen && (
        <Popup
          handleClose={togglePopup}
          content={
            <>
              <TableauQuiz style={{transform: "scale(" + scale + ")" }}/>
            </>
          }
        />
      )}
    </div>
  );
};

export default Tableau;
