import React, { useState, useEffect, useRef, useCallback } from "react";
import AdminSidebar from "components/Sidebar/AdminSidebar";
import styles from "./Customer.module.css";
import CardTable from "components/Cards/CardTable";
import BlueBackground from "components/Cards/BlueBackground";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { format } from 'date-fns'

const Customer = (props) => {
    const pageSize = 15;
    const [data, setData] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [page, setPage] = useState(0);

  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);
  const [openDateFilter, setOpenDateFilter] = useState(false);
  const wrapperRef = useRef(null);
  const getLocalStorageWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
    };

    const getCustomerPaginated = useCallback(() => {
        var postData = new URLSearchParams();
        postData.append("username", getLocalStorageWithExpiry("Name"));
        postData.append("pageNumber", page);
        postData.append("pageSize", pageSize);

        // POST request with a JSON body using fetch
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "application/json",
            },
            body: postData.toString(),
        };
        requestOptions.headers[
            "Authorization"
        ] = `Bearer ${getLocalStorageWithExpiry("Access_Token")}`;

        setButtonDisabled(true);

        fetch(
            `${process.env.REACT_APP_PGA_USER_API_URL}/User/GetAllCustomers`,
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.isSucces === true) {
                    setData(data.result);
                }
                setButtonDisabled(false);
            });

    }, [page]);

  useEffect(() => {
      getCustomerPaginated();
  }, [getCustomerPaginated]);

  const useDetectClickOutside = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenDateFilter(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

    useDetectClickOutside(wrapperRef);

  function downloadCustomers(e) {
    e.preventDefault();
    let startDate, endDate;
    const postData = new URLSearchParams();
    postData.append("username", getLocalStorageWithExpiry("Name"));
    if(dateRange[0].startDate){
      startDate = format(new Date(dateRange[0].startDate), 'yyyy-MM-dd');
      postData.append("beginningDate", startDate);
    }
    
    if(dateRange[0].endDate){
      endDate = format(new Date(dateRange[0].endDate), 'yyyy-MM-dd');
      postData.append("endDate", endDate);
    }else if(dateRange[0].startDate){
      endDate = format(new Date(dateRange[0].startDate), 'yyyy-MM-dd');
      postData.append("endDate", endDate);
    }
   
    // POST request with a JSON body using fetch
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      body: postData.toString(),
    };
    requestOptions.headers[
      "Authorization"
    ] = `Bearer ${getLocalStorageWithExpiry("Access_Token")}`;

    fetch(
      `${process.env.REACT_APP_PGA_USER_API_URL}/User/GetCustomerExcelFile`,
      requestOptions
    )
      .then((response) => response.blob())
      .then((responseData) => {
        if (responseData) {
          console.log(responseData);
          const blob = new Blob([responseData], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "customers.xlsx";
          link.click();
        }
      });
  }

  const dateRangeFilter = (val)=>{
    let startDate, endDate;
    var postData = new URLSearchParams();
    postData.append("username", getLocalStorageWithExpiry("Name"));
    if(!val){
      if(dateRange[0].startDate){
        startDate = format(new Date(dateRange[0].startDate), 'yyyy-MM-dd');
        postData.append("beginningDate", startDate);
      }
      if(dateRange[0].endDate){
        endDate = format(new Date(dateRange[0].endDate), 'yyyy-MM-dd');
        postData.append("endDate", endDate);
      }else if(dateRange[0].startDate){
        endDate = format(new Date(dateRange[0].startDate), 'yyyy-MM-dd');
        postData.append("endDate", endDate);
      }
    }
    
    // POST request with a JSON body using fetch
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      body: postData.toString(),
    };
    requestOptions.headers[
      "Authorization"
    ] = `Bearer ${getLocalStorageWithExpiry("Access_Token")}`;

    fetch(
      `${process.env.REACT_APP_PGA_USER_API_URL}/User/GetAllCustomers`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.isSucces === true) {
          setData(data.result);
        }
      });
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "rgb(241,245,249)",
          height: "100vh",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <AdminSidebar />
          <BlueBackground />
          <div className={styles.customer}>
            <h3
              className={styles.title}
              style={{ color: "white", fontSize: "14px" }}
            >
              Customers
            </h3>
            <div>
            </div>
           
            <div className={styles.dlbutton}>
              <form onSubmit={downloadCustomers}>
                <button
                  className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                >
                  Download
                </button>
                
              </form>
              <form onSubmit={(e)=>{e.preventDefault();setOpenDateFilter(!openDateFilter)}} ref={wrapperRef}>
              <button
                  className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                >
                  Filter
                </button>
                {openDateFilter && <div className={styles.daterangeSelectionContainer}>
                  <div className={styles.daterangeSelection}>
                    <DateRangePicker
                      ranges={dateRange}
                      maxDate={new Date()}
                      onChange={(item)=>{setDateRange([item.selection])}}
                    />
                    <button type="button" 
                            className="bg-blue text-white text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            onClick={()=>{
                              setOpenDateFilter(false);
                              dateRangeFilter();
                              }
                              }
                            >Apply</button>
                    <button type="button" 
                            className="bg-white border text-black text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            onClick={()=>{
                              setOpenDateFilter(false);
                              dateRangeFilter("clear");
                              setDateRange([
                              {
                                startDate: null,
                                endDate: null,
                                key: 'selection'
                              }
                            ])}}
                    >Clear</button>
                  </div>
              </div>}
              </form>
              
            </div>
            <div className={styles.table}>
              <CardTable data={data} color="lightBlue" />
            </div>
            <div className={styles.navbutton}>
                <form onSubmit={(e) => { e.preventDefault(); }}>
                    <button
                        className="text-blueGray-600 w-8 font-bold"
                        onClick={() => setPage(page - 1)}
                        disabled={page === 0 || buttonDisabled}> &lt;&lt; </button>
                    <span className="font-bold text-blueGray-600 w-8">{page}</span>
                    <button className="text-blueGray-600 w-8 font-bold"
                        onClick={() => setPage(page + 1) }
                        disabled={data.length < pageSize || buttonDisabled} > &gt;&gt; </button>
                </form>
            </div>
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default Customer;
