import React, { Component } from "react";
import { hotjar } from "react-hotjar";

class ChangePassword extends Component {
  state = {
    email: "",
    password: "",
    key: "",
    confirmPassword:"",
    requestGotError: false,
    requestErrorMessage: "",
    isSuccess:false
  };

  componentDidMount() {
    hotjar.initialize("2739117", 6);
  }
  changeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  validateForm = () => {
    const {
      email,
      password,
      confirmPassword,
      key      
    } = this.state;

    //Reset validating flags
    this.setState({
      passwordValid: true,
      passwordsSame: true,
    });

    if (!this.validateEmail(email)) {
      this.setState({ requestGotError: true,requestErrorMessage:"email is not valid!" });
      return false;
    }
    if (key.length === 0) {
        this.setState({ requestGotError: true,requestErrorMessage:"please enter key!" });
        return false;
    }

    if (password.length < 6) {
        this.setState({ requestGotError: true,requestErrorMessage:"password length must be longer then 6 characters!" });
        return false;
    }

    if (password !== confirmPassword) {
        this.setState({ requestGotError: true,requestErrorMessage:"passwords must match!" });
        return false;
    }

    return true;
  };

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  changePassword = (e) => {
    e.preventDefault();

    const { email, password,key } = this.state;

    if(!this.validateForm(this.state))
        return;

    var postData = new URLSearchParams();
    postData.append("username", email);
    postData.append("password", password);
    postData.append("key", key);
    // POST request with a JSON body using fetch
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      body: postData.toString(),
    };

    fetch(
      `${process.env.REACT_APP_PGA_USER_API_URL}/User/ChangePassword`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        //debugger;
        if (data.isSucces === true) {
            this.setState({ isSuccess: true,requestErrorMessage:"", email: "",
            password: "",
            key: "",
            confirmPassword:"" });
            setTimeout(function(){
                window.location.href = "/login";
            }, 2000);
            
        } else {
            this.setState({ isSuccess:false,requestGotError: true,requestErrorMessage:data.message });
        }
      });
  };

  render() {
    const { email, password,key,confirmPassword, requestErrorMessage, requestGotError,isSuccess } =
      this.state;
    return (
      <div className="container mx-auto px-4 h-full vertical-center">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-blueGray-400 text-center mb-3 font-bold">
                  <small>Change Password</small>
                </div>
                <form onSubmit={this.changePassword}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      name="email"
                      id="email"
                      value={email}
                      onChange={this.changeInput}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Key
                    </label>
                    <input
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="key"
                      name="key"
                      id="key"
                      value={key}
                      onChange={this.changeInput}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      value={password}
                      id="password"
                      name="password"
                      onChange={this.changeInput}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      id="confirmPassword"
                      name="confirmPassword"
                      onChange={this.changeInput}
                    />
                  </div>

                  <div
                    className="alert alert-warning"
                    role="alert"
                    style={
                      requestGotError ? { color: "red" } : { display: "none" }
                    }
                  >
                    {requestErrorMessage}
                  </div>
                  <div
                    className="alert alert-warning"
                    role="alert"
                    style={
                        isSuccess ? { color: "green" } : { display: "none" }
                    }
                  >
                    Password changed successfully!
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Change
                    </button>
                  </div>
                </form>
              </div>
            </div>            
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePassword;
