import React from "react";
import PGALandingPricing from "components/pricing/PGALandingPricing";
import Button from "@notus-pro/react/Button";
const TableauPricing = (props) => {
  const buttonProps = {
    children: "Get Started for free",
    color: "dark",
  };

  return (
    <section className="mt-48 md:mt-40 pb-32 relative" id="product">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PGALandingPricing hideSubscribe={true} />
        <Button
          onClick={props.togglePopup}
          style={{ marginTop: "20px", borderRadius: "5px" }}
          {...buttonProps}
        />
      </div>
    </section>
  );
};

export default TableauPricing;
