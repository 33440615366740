import React from "react";

const TableauQuiz = (props) => {

  
  return (
    <>
      <iframe
        title="Tableau Quiz"
        id="interactApp62e80295412ee3001831f991"
        width="800"
        height="650"
        style={{
          border: "none",
          maxWidth: "100%",
          margin: "0",
          borderRadius: "5px",
        }}
        allowTransparency="true"
        frameborder="0"
        src="https://quiz.tryinteract.com/#/62e80295412ee3001831f991?method=iframe&mobile=1"
        {...props}
      />
    </>
  );
};

export default TableauQuiz
