/*eslint-disable*/
import React, { useState, useEffect } from "react";
import Button from "@notus-pro/react/Button";
import { Link } from "react-router-dom";
import styles from "./navbar.module.css";
import DropdownNavbar from "./DropdownNavbar";

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [name, setName] = useState("");
  const [signoutVisible, setSignoutVisible] = useState(false);
  const [signinVisible, setSigninVisible] = useState(true);

  const buttonProps = {
    children: "Get Started for free",
    color: "dark",
  };

  const getLocalStorageWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  };

  useEffect(() => {
    setName(getLocalStorageWithExpiry("Name")); //30 mins

    if (name === null || name === undefined) {
      setSignoutVisible(false);
      setSigninVisible(true);
    } else {
      setSignoutVisible(true);
      setSigninVisible(false);
    }
  }, [name]);

  const signOut = () => {
    localStorage.removeItem("Name");
    localStorage.removeItem("Access_Token");
    window.location.href = "/";
  };

  /*List of links for "More" dropdown*/
  const dropdownlist = {
    text: "Developers",
    items: [
      {
        href: "https://docs.powerpointgeneratorapi.com/",
        children: "Documentation",
        target: "_blank",
      },
      {
        href: "https://github.com/Indico-Labs/PowerPoint-Generator-API-Community/discussions",
        children: "Forum",
        target: "_blank",
      },
    ],
    navColor: "light",
  };

  return (
    <>
      <nav className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
              to="/"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "5px",
                }}
              >
                <img
                  src="/logo.png"
                  style={{
                    width: "15px",
                    height: "21px",
                  }}
                />
                <p style={{ paddingLeft: "15px" }}>PowerPoint Generator API</p>
              </div>
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center  lg:shadow-none" +
              (navbarOpen ? " block rounded shadow-lg" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className={"flex flex-col lg:flex-row list-none lg:ml-auto "+styles.dropdownUl}>
              {props.tableau ? (
                <>
                  <li>
                    <Button
                      onClick={props.togglePopup}
                      style={{ borderRadius: "5px" }}
                      {...buttonProps}
                    />
                  </li>{" "}
                </>
              ) : (
                <>
                  <li className="flex items-center">
                    <a
                      className="lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                      href="/#product"
                    >
                      {/* <i className="lg:text-blueGray-200 text-blueGray-400 fab fa-twitter text-lg leading-lg " /> */}
                      <span className="inline-block ml-2">Product</span>
                    </a>
                  </li>
                  <li className="flex items-center">
                    <a
                      className="lg:hover:text-blueGray-200 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                      href="/#pricing"
                    >
                      {/* <i className="lg:text-blueGray-200 text-blueGray-400 fab fa-github text-lg leading-lg " /> */}
                      <span className="inline-block ml-2">Pricing</span>
                    </a>
                  </li>
                  <li>
                    <DropdownNavbar {...dropdownlist} />
                  </li>
                  {signinVisible ? (
                    <li className="flex items-center">
                      <Link to="/login">
                        <button className={styles.button}>Sign in</button>
                      </Link>
                    </li>
                  ) : null}
                  {signoutVisible ? (
                    <li className="flex items-center">
                      <Link onClick={signOut}>
                        <button className={styles.button}>Sign out</button>
                      </Link>
                    </li>
                  ) : null}{" "}
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
